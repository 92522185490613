export const images = {
  techBanner: require("./assets/techBanner.png"),
  techBanner2: require("./assets/techBanner2.png"),
  homeLeftCard: require("./assets/homeCardLeft.png"),
  homeInnovate: require("./assets/homeInnovation.png"),
  homeInspire: require("./assets/homeInspire.png"),
  homeCollaborate: require("./assets/homeCollaborate.png"),
  homeAchieve: require("./assets/homeAchieve.png"),
  homeStatsImg: require("./assets/homeStatsImg.png"),
  homeSlideAbout: require("./assets/homeSlideAbout.jpg"),
  homeSlideEner: require("./assets/homeSlideEner.jpg"),
  homeSlideConsult: require("./assets/homeSlideConsult.jpg"),
  homeSlideSolar: require("./assets/homeSlideSolar.jpg"),
  homeSlideTech: require("./assets/homeSlideTech.jpg"),

  // menu: require("./assets/menu.png"),
  commodity: require("./assets/Commodity.svg"),
  // technology: require("./assets/Technology.svg"),
  light: require("./assets/Light.svg"),
  lightBg: require("./assets/lightsBg.webp"),
  energy: require("./assets/Energy.svg"),
  agni: require("./assets/agni.png"),
  eco: require("./assets/eco.png"),
  agarwal: require("./assets/agarwal.png"),
  aia: require("./assets/aia.png"),
  alternativeEnergy: require("./assets/alternativeEnergy.jpg"),
  anand: require("./assets/anand.png"),
  annapurna: require("./assets/annapurna.png"),
  ansal: require("./assets/ansal.png"),
  checkbox: require("./assets/checkbox.svg"),
  conti: require("./assets/conti.png"),
  desktopLogo: require("./assets/desktopLogo.png"),
  fortis: require("./assets/fortis.png"),
  gm: require("./assets/gm.png"),
  gupta: require("./assets/gupta.png"),
  h: require("./assets/h.png"),
  heidelberg: require("./assets/heidelberg.png"),
  hya: require("./assets/hya.png"),
  Image2: require("./assets/Image2.png"),
  img1: require("./assets/img1.png"),
  img2: require("./assets/img2.png"),
  // img3: require("./assets/img3.jpg"),
  img4: require("./assets/img4.png"),
  img5: require("./assets/img5.png"),
  jai: require("./assets/jai.png"),
  jw: require("./assets/jw.png"),
  k: require("./assets/k.png"),
  kair: require("./assets/kair.png"),
  max: require("./assets/max.png"),
  meghmanii: require("./assets/meghmanii.png"),
  mittal: require("./assets/mittal.png"),
  oreva: require("./assets/oreva.png"),
  pacific: require("./assets/pacific.png"),
  paras2: require("./assets/paras2.png"),
  partner: require("./assets/partner.png"),
  ramnikk: require("./assets/ramnikk.png"),
  raymond: require("./assets/raymond.png"),
  rishi: require("./assets/rishi.png"),
  soktas: require("./assets/soktas.png"),
  sunEnergyConcept: require("./assets/sunEnergyConcept.jpg"),
  super1: require("./assets/super1.png"),
  thomson: require("./assets/thomson.png"),
  unnamed: require("./assets/unnamed.png"),
  //about
  aboutBanner: require("./assets/aboutBanner.png"),
  aboutImg1: require("./assets/aboutImg1.png"),
  aboutImg2: require("./assets/aboutImg2.png"),
  aboutImg3: require("./assets/aboutImg3.png"),
  aboutImg4: require("./assets/aboutImg4.png"),
  aboutImg5: require("./assets/aboutImg5.png"),
  aboutImg6: require("./assets/aboutImg6.png"),
  play: require("./assets/play.png"),
  profile: require("./assets/profile.png"),
  techImg1: require("./assets/techImg1.png"),
  solarPlantBg: require("./assets/solarPlantBg.jpg"),
  solarEnergy: require("./assets/solarEnergy.png"),
  solar: require("./assets/solar.jpg"),
  newSolarBack: require("./assets/newSolarBack.jpg"),
  consultancyBanner: require("./assets/consultancyBanner.jpg"),
  consultancy: require("./assets/consultancy.jpg"),
  metering: require("./assets/metering.png"),
  efficiency: require("./assets/efficiency.png"),
  windTurbine: require("./assets/windTurbine.png"),
  realTime: require("./assets/realTime.png"),
  profit: require("./assets/profit.png"),
  android: require("./assets/android.png"),
  report: require("./assets/report.png"),
  error: require("./assets/error.png"),
  meterImg2: require("./assets/meterImg2.jpg"),
  calculator: require("./assets/calculator.jpg"),
  energyBanner: require("./assets/energyBanner.png"),
  manpoer: require("./assets/manpoer.jpg"),
  manpowerImg4: require("./assets/manpowerImg4.jpg"),
  intro: require("./assets/intro.jpg"),
  manpoweConsulting: require("./assets/manpowerConsulting.jpg"),
  consultancyBanner1: require("./assets/consultancyBanner1.png"),
  contactBg: require("./assets/contactBg.png"),
  mediaBg: require("./assets/mediaBg.png"),
  careerSec: require("./assets/careerSec.png"),
  consultImg: require("./assets/consultImg.png"),
  techSecBanner: require("./assets/techSecBanner.png"),
  finalVideo: require("./assets/FinalVideo.mp4"),
  //metering
  group126: require("./assets/Group126.png"),
  group127: require("./assets/Group127.png"),
  group128: require("./assets/Group128.png"),
  group129: require("./assets/Group129.png"),
  group130: require("./assets/Group130.png"),
  group131: require("./assets/Group131.png"),
  group132: require("./assets/Group132.png"),
  //service-it
  tech: require("./assets/technology.svg"),
  ai: require("./assets/ai.jpg"),
  blockchain: require("./assets/blockchain.jpg"),
  cmsWebsite: require("./assets/cmsWebsite.jpg"),
  dataAnalysis: require("./assets/dataAnalysis.jpg"),
  erpSol: require("./assets/erpSolutions.jpg"),
  gisApp: require("./assets/gis-applicaton.jpg"),
  mobileApp: require("./assets/mobile-app.jpg"),
  ml: require("./assets/ml.jpg"),
  evMobileApp: require("./assets/evMobileApp.jpg"),
  processAutomation: require("./assets/processAutomation.jpg"),
  serConsRight: require("./assets/serConsRight.png"),
  serConsLeft: require("./assets/serConsLeft.png"),
  complicated: require("./assets/complicated.png"),
  interpersonalSkills: require("./assets/interpersonalSkills.png"),
  statistical: require("./assets/statistical.png"),
  rating: require("./assets/rating.png"),
  skill: require("./assets/skill.png"),
  analyticalSkill: require("./assets/analyticalSkill.png"),
  designThinking: require("./assets/designThinking.png"),
  leadership: require("./assets/leadership.png"),
  manpowerImg2: require("./assets/manpowerImg2.png"),
  //solar
  solarGal0: require("./assets/solarGal0.png"),
  solarGal1: require("./assets/solarGal1.png"),
  solarGal2: require("./assets/solarGal2.png"),
  solarGal3: require("./assets/solarGal3.png"),
  solarImage1: require("./assets/solarImage1.png"),
  solarImage2: require("./assets/solarImage2.png"),
  solarImage3: require("./assets/solarImage3.png"),
  solarInnerB2: require("./assets/solarInnerB2.jpg"),
  solarPortfolio1: require("./assets/solarPortfolio1.jpg"),
  solarPortfolio2: require("./assets/solarPortfolio2.jpg"),
  solarPortfolio3: require("./assets/solarPortfolio3.jpg"),
  solarWhyBg: require("./assets/solarWhyBg.png"),
  solarInstallation: require("./assets/solarInstallation.jpg"),
  solarConsultancy: require("./assets/solarConsultancy.jpg"),
  solarMaintain: require("./assets/solarMaintain.jpg"),
  rightArrow: require("./assets/rightArrow.png"),
};
