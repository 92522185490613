import React from "react";
import SalesContact from "./SalesContact";
import Appointment from "./Appointment";

function Support({ mail, id }) {
  return (
    <div
      className="contact dp m-0 solar-sec my-lg-4"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div className="container" id={id}>
        <div className="row">
          <SalesContact mail={mail} />
          <Appointment
            formType={
              mail === "solar@smartechadvisory.com" ? "solar" : "others"
            }
          />
        </div>
      </div>
    </div>
  );
}

export default Support;
