import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../../utils";

function WhyUs() {
  const [sectionData, setSectionData] = useState([]);
  const [sectionImg, setSectionImage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `
                           {
  homePages(where: { id: 95 }) {
    nodes {
      id
      title
      content
      featuredImage {
        node {
          sourceUrl
        }
      }
    }
  }
}
            `,
          }),
        });
        const responseData = await response.json();
        // console.log(responseData.data.homePages.nodes[0].content);
        setSectionData(responseData.data.homePages.nodes[0]);
        setSectionImage(
          responseData.data.homePages.nodes[0].featuredImage.node.sourceUrl
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="blue-bg">
      <div className="container p-0">
        <div className="row m-0 px-3 px-lg-0">
          <div
            className="col-12 col-md-6 mt-3 my-lg-5 d-flex justify-content-center flex-column main_ai"
            data-aos="slide-right"
            data-aos-duration="800"
            dangerouslySetInnerHTML={{
              __html: sectionData["content"],
            }}
          ></div>
          <div
            className="col-12 col-md-6 "
            data-aos="slide-left"
            data-aos-duration="800"
          >
            <img src={sectionImg} alt="" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyUs;
