import React, { useEffect, useState } from "react";
import FeatureCard from "../Cards/FeatureCard";
import { images } from "../../../res/images";
import { Card } from "react-bootstrap";
import { BASE_URL } from "../../../utils";

function Section2() {
  const [leftCardData, setLeftCardData] = useState([]);
  const [rightCardData, setRightCardData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `
                          {
  aboutPageSections(where: {termTaxonomyId: "9"}) {
    
    nodes{
    leftdata: aboutPages(where: {id: 183, orderby: {field: DATE, order: ASC}}) {
        nodes {
          content
          allPageData {
            mainHeading
            subHeading
          }
        }
      }
    rightdata: aboutPages(where: {orderby: {field: DATE, order: ASC}, notIn: "183"}) {
        nodes {
          title
          content
          featuredImage {
            node {
              sourceUrl
            }
          }
         
        }
      } 
    }
  }
}
            `,
          }),
        });
        const responseData = await response.json();
        // console.log(
        //   responseData.data.aboutPageSections.nodes[0].rightdata.nodes[0]
        //     .featuredImage.node.sourceUrl
        // );
        setLeftCardData(
          responseData.data.aboutPageSections.nodes[0].leftdata.nodes[0]
        );
        setRightCardData(
          responseData.data.aboutPageSections.nodes[0].rightdata.nodes
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="container px-4" id="about">
      <div className="row mt-3 mt-lg-5">
        <div
          className="col-12 col-md-12 col-lg-5 col-xl-6 main-left"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          {leftCardData.allPageData && (
            <>
              <h4 className="main-heading">
                {leftCardData.allPageData.mainHeading}
              </h4>
              <h4 className="sec2-content text-start">
                {leftCardData.allPageData.subHeading}
              </h4>
            </>
          )}
          <div
            dangerouslySetInnerHTML={{
              __html: leftCardData["content"],
            }}
          ></div>
          <div className="center mt-3 mb-5">
            <div className="pulse">
              <i className="ri-play-fill"></i>
            </div>
          </div>
        </div>
        <div
          className="col-12 col-md-12 col-lg-7 col-xl-6 "
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <div className="row d-flex flex-wrap right-main">
            {rightCardData && rightCardData.length > 0 && (
              <>
                <div className="col-sm-5 p-0 ps-md-3 my-0 mx-2 mx-md-0 my-md-0 me-3">
                  <FeatureCard
                    title={rightCardData[0].title}
                    desc={rightCardData[0].content}
                    bgImgUrl={
                      rightCardData[0].featuredImage.node.sourceUrl
                        ? rightCardData[0].featuredImage.node.sourceUrl
                        : null
                    }
                  />
                  <FeatureCard
                    title={rightCardData[1].title}
                    desc={rightCardData[1].content}
                    bgImgUrl={
                      rightCardData[1].featuredImage.node.sourceUrl
                        ? rightCardData[1].featuredImage.node.sourceUrl
                        : null
                    }
                  />
                </div>
                <div className="col-sm-5 p-0 ps-md-3 ps-lg-0 ms-md-0 my-md-4 me-md-3 ">
                  <FeatureCard
                    title={rightCardData[2].title}
                    desc={rightCardData[2].content}
                    bgImgUrl={
                      rightCardData[2].featuredImage.node.sourceUrl
                        ? rightCardData[2].featuredImage.node.sourceUrl
                        : null
                    }
                  />
                  <Card
                    className=" card1 a-card shadow-lg border-0 mb-3"
                    style={{ backgroundImage: `url(${images.aboutImg4})` }}
                  >
                    <Card.Body>
                      <h6 className="a-card-title text-light my-5 mt-5 pt-5">
                        Our Certification
                      </h6>
                      <span className="a-card-text w-100 justify-content-center mt-5 pt-5">
                        <a className="smButton2 smcustom" href="#">
                          View Certificate
                          <div className="icon">
                            <svg
                              height="24"
                              width="24"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M0 0h24v24H0z" fill="none"></path>
                              <path
                                d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </div>
                        </a>
                      </span>
                    </Card.Body>
                  </Card>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section2;
