import React from "react";

function HeaderCard({ icon, title, desc, link }) {
  return (
    <div className="col-sm-12 col-md-4 col-lg-6 col-xl-4 my-2 my-md-0">
      <div className="card py-2">
        <div className="card-body text-center sec-banner-card">
          <div className="head-icon">
            <img src={icon} alt="" className="img-fluid" />
            <h5 className="card-title fw-bold my-2">{title}</h5>
            <div dangerouslySetInnerHTML={{ __html: desc }}></div>
          </div>

          <a className="smButton gotoButton" id="goto3" href={`#${link}`}>
            Learn More
            <div className="icon">
              <svg
                height="24"
                width="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path
                  d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default HeaderCard;
