import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../../utils";

function SectionHeader() {
  const [headData, setHeadData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `
              {
                contactUsPages(where: {id: 598}) {
                  nodes {
                    title
                  }
                }
              }
            `,
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        const fetchedTitle =
          data?.data?.contactUsPages?.nodes[0]?.title ?? null;
        setHeadData(fetchedTitle);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="row pt-2">
      <div className="col-12">
        <h2 className="yellow-border-bt-4px pb-2 font-weight700 text-u text-start effect-underline">
          <a href="#" className="effect-underline ourtools_head">
            {headData}
          </a>
        </h2>
      </div>
    </div>
  );
}

export default SectionHeader;
