import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../../utils";

function Section2() {
  const [sectionData, setSectionData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  consultancyDivisionPages(where: {id: 279}) {
    nodes {
      content
       featuredImage {
            node {
              sourceUrl
            }
          }
    }
  }
}
`,
          }),
        });
        const responseData = await response.json();
        // console.log(responseData.data.consultancyDivisionPages.nodes[0]);
        setSectionData(responseData.data.consultancyDivisionPages.nodes[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="" data-aos="slide-right" data-aos-duration="500">
      <div className="container mt-5 pt-5 p-0">
        <div className="row me-0">
          {sectionData &&
            sectionData.featuredImage &&
            sectionData.featuredImage.node && (
              <>
                <div className="col-lg-5 pe-0 ps-0">
                  <img
                    src={sectionData.featuredImage.node.sourceUrl}
                    alt=""
                    className="img-fluid consult-target-img"
                  />
                </div>
                <div className="col-lg-7 target-right-col net-target align-items-center d-flex justify-content-center flex-column  py-2 py-xlg-3 mt-lg-0">
                  <div
                    className="px-4 para_text"
                    dangerouslySetInnerHTML={{ __html: sectionData.content }}
                  ></div>
                  <a href="#contactUsForm" className="smButton2">
                    Contact Us
                    <div className="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </a>
                </div>
              </>
            )}
        </div>
      </div>
    </div>
  );
}

export default Section2;
