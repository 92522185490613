import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../utils";

function Section2() {
  const [sectionData, setSectionData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  iTManpowerPages(where: {id:475}) {
    nodes {
    content
     featuredImage {
            node {
              sourceUrl
            }
          }
         allPageData {
            mainHeading
            subHeading
          }
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(responseData.data.iTManpowerPages.nodes[0]);
        setSectionData(responseData.data.iTManpowerPages.nodes[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="who-we-manpower manP mt-5">
      <div className="row">
        <div className="col-12 d-flex flex-column justify-content-center align-items-center">
          {sectionData && sectionData.allPageData && (
            <p
              className="text-uppercase fw-bolder fs-3 head-line my-1 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              {sectionData.allPageData.mainHeading}
            </p>
          )}
          {sectionData && sectionData.allPageData && (
            <p
              className="text-capitalize fw-bolder fs-4 fs-md-3 head-line aos-init aos-animate greenClr text-center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              {sectionData.allPageData.subHeading}
            </p>
          )}
        </div>

        <div
          className="col-md-12 col-lg-6 d-flex align-items-lg-center"
          data-aos="slide-left"
          data-aos-duration="800"
        >
          <div className="manpower-service-cont d-flex justify-content-start">
            <div
              className="img-cont-who-manpower rounded"
              data-aos="fade-right"
              data-aos-duration="1000"
            ></div>
          </div>
        </div>
        <div
          className="col-md-12 col-lg-6 "
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <div dangerouslySetInnerHTML={{ __html: sectionData.content }}></div>
          <p className="ms-lg-4" data-aos="fade-up" data-aos-duration="1000">
            Get intrigued about our service pricing?
            <a href="/contact" className="text-decoration-underline">
              Learn More
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Section2;
