import React, { useEffect, useState } from "react";
import FeatureCard from "../Cards/FeatureCard";
import { BASE_URL } from "../../../utils";

function Features() {
  const [leftCardData, setLeftCardData] = useState("");
  const [rightCardData, setRightCardData] = useState([]);

  useEffect(() => {
    const fetchLeftCardData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `
              {
                homePageSections(where: {termTaxonomyId: "5"}) {
                  nodes {
                    id
                    name
                    homePages(where: {id: 111, orderby: {field: DATE, order: ASC}}) {
                      nodes {
                        id
                        title
                        content
                        featuredImage {
                          node {
                            sourceUrl
                          }
                        }
                      }
                    }
                  }
                }
              }
            `,
          }),
        });
        const responseData = await response.json();
        setLeftCardData(
          responseData.data.homePageSections.nodes[0].homePages.nodes[0]
            .featuredImage.node.sourceUrl
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchRightCardData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `
              {
                homePageSections(where: {termTaxonomyId: "5"}) {
                  nodes {
                    id
                    name
                    homePages(where: {orderby: {field: DATE, order: ASC}, notIn: "111"}) {
                      nodes {
                        id
                        title
                        content
                        featuredImage {
                          node {
                            sourceUrl
                          }
                        }
                      }
                    }
                  }
                }
              }
            `,
          }),
        });
        const responseData = await response.json();
        // console.log(
        //   responseData.data.homePageSections.nodes[0].homePages.nodes
        // );
        setRightCardData(
          responseData.data.homePageSections.nodes[0].homePages.nodes
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchLeftCardData();
    fetchRightCardData();
  }, []);

  return (
    <div
      className="mb-0 mt-md-2 mb-md-2 mt-lg-5 my-lg-2 mt-4 mt-md-2 sec2-bg pt-3 pt-md-5 pt-lg-0"
      id="indexSec2"
      data-aos="fade-up"
      data-aos-duration="800"
    >
      <h2 className="pb-0 pt-lg-3 pb-lg-2 m-0">
        <span className="sec2-content">Make the Most of Every Moment</span>
      </h2>
      <div className="container text-center p-0 ">
        <div className="row m-md-0 mx-3 d-flex justify-content-center pb-2">
          <div
            className="col-sm-12 col-md-12 col-lg-4 col-xl-4 p-0 image_left_sec card shadow-lg rounded-3 left-card m-card mb-md-2 mt-2"
            data-aos="fade-up-right"
            data-aos-duration="1000"
          >
            <img
              className="shadow-lg rounded-3 card-body p-0 h-100 img-fluid"
              src={leftCardData}
              alt=""
              style={{ objectFit: "cover", width: "100%" }}
            />
          </div>
          {/* <div
            className="col-sm-12 col-md-4 col-lg-4 col-xl-4 p-0 card shadow-lg rounded-3 left-card m-card mb-md-2"
            data-aos="fade-up-right"
            data-aos-duration="1000"
            style={{
              background: `url(${leftCardData}) center`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></div> */}
          <div
            className="right-index col-sm-12 col-md-12 col-lg-8 col-xl-6 pl-2"
            data-aos="fade-up-left"
            data-aos-duration="1000"
          >
            {rightCardData &&
              (() => {
                const rows = [];
                for (let id = 0; id < rightCardData.length; id += 2) {
                  const firstItem = rightCardData[id];
                  const secondItem = rightCardData[id + 1];
                  const row = (
                    <div
                      className="row h-50 pb-sm-0 pb-md-2 pb-lg-2 mt-sm-2 mt-md-0 py-md-2 card_left_section"
                      key={id}
                    >
                      <FeatureCard
                        key={firstItem.id}
                        imgUrl={firstItem.featuredImage.node.sourceUrl}
                        title={firstItem.title}
                        desc={firstItem.content}
                      />
                      {secondItem && (
                        <FeatureCard
                          key={secondItem.id}
                          imgUrl={secondItem.featuredImage.node.sourceUrl}
                          title={secondItem.title}
                          desc={secondItem.content}
                        />
                      )}
                    </div>
                  );
                  rows.push(row);
                }
                return rows;
              })()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
