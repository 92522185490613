import React, { useEffect, useState } from "react";
import solarServiceInstall from "../../../res/assets/solarServiceInstall.svg";
import solarMaintaine from "../../../res/assets/solarMaintaine.svg";
import solarCommission from "../../../res/assets/solarCommission.svg";
import { useLocation } from "react-router-dom"; // Import useLocation hook

import { images } from "../../../res/images";

function ServiceCard({ id, title, desc, subTitle, points, link }) {
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(location.hash === `#${link}`);

  var imgArr = [
    images.solarInstallation,
    images.solarMaintain,
    images.solarConsultancy,
  ];
  var iconArr = [solarServiceInstall, solarMaintaine, solarCommission];

  const showPara = (id) => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    setIsOpen(location.hash === `#${link}`);
  }, [location.hash, link]);
  return (
    <div className="swiper-slide" data-aos="fade-up" data-aos-duration="800">
      <div className="card" id={link}>
        <img
          // src={images.solarInstallation}
          //src={imgUrl}
          src={imgArr[id]}
          className="card-img-top img-fluid"
          alt="..."
        />
        <div className="card-body d-flex flex-column align-items-center justify-content-between">
          <div className="icon-cont-card-Top">
            <img
              // src={solarServiceInstall}
              //src={iconUrl}
              src={iconArr[id]}
              className="img-fluid"
              alt=""
            />
          </div>
          <h5 className="card-title my-2 fw-bold">{title}</h5>
          <p className="card-text text-of-card" data-aos="fade-up">
            {desc}
          </p>
          <div
            className="hide-cont"
            id={"moreContent" + id}
            style={{ display: isOpen ? "block" : "none" }}
          >
            <ul>
              <p className="fw-bold">{subTitle}</p>
              {points.map((item, id) => (
                <li key={id}>{item}</li>
              ))}
            </ul>
          </div>
          <button
            className="smButton"
            id={"toggleButton" + id}
            onClick={() => showPara(id)}
          >
            {isOpen ? "Read Less" : "Read More"}
            <div className="icon">
              <svg
                height="24"
                width="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path
                  d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                  fill="currentColor"
                ></path>
              </svg>{" "}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ServiceCard;
