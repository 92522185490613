import React, { useEffect, useState } from "react";
import Slide from "./Slide";
import { BASE_URL } from "../../../utils";

function Services() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  solarPageSections(where: {termTaxonomyId: "18"}) {
    nodes {
      topdata: solarPages(where: {id: 310, orderby: {field: DATE, order: ASC}}) {
        nodes {
          featuredImage {
            node {
              sourceUrl
            }
          }
          allPageData {
            mainHeading
          }
        }
      }
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(
        //   responseData.data.solarPageSections.nodes[0].topdata.nodes[0]
        // );
        setData(responseData.data.solarPageSections.nodes[0].topdata.nodes[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div>
      <section className="our-solutions solar-sec">
        <div
          className="container-fluid dp"
          style={{
            color: "white",
            background:
              data &&
              data.featuredImage &&
              data.featuredImage.node &&
              `linear-gradient(290deg, rgba(0, 0, 0, 0.819), #439a7dbc), url(${data.featuredImage.node.sourceUrl})`,
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "cover",
            height: "510px",
          }}
        >
          <div className="row justify-content-center">
            <div className="col-11 col-md-7 text-center pt-3 pt-md-5">
              {data && data.allPageData && (
                <p
                  className="fs-2 fw-bolder head-line text-center"
                  data-aos="fade-up"
                >
                  {data.allPageData.mainHeading}
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
      <div className="overlap-cont dp">
        <div className="row p-0">
          <div className="col-12">
            <Slide />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
