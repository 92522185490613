import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { Autoplay, EffectCoverflow, Pagination } from "swiper/modules";
import { images } from "../../../res/images";
import ProjectCard from "../Cards/ProjectCard";

export default function Slide({ slideData }) {
  const projectSlide = [
    {
      id: 1,
      imgUrl: images.solarGal2,
    },
    {
      id: 2,
      imgUrl: images.solarGal0,
    },
    {
      id: 3,
      imgUrl: images.solarGal1,
    },

    {
      id: 4,
      imgUrl: images.solarGal3,
    },
    {
      id: 1,
      imgUrl: images.solarGal0,
    },
    {
      id: 5,
      imgUrl: images.solarImage1,
    },
    {
      id: 6,
      imgUrl: images.solarImage2,
    },
    {
      id: 7,
      imgUrl: images.solarImage3,
    },
    {
      id: 8,
      imgUrl: images.solarInnerB2,
    },
    {
      id: 9,
      imgUrl: images.solarPortfolio1,
    },
    {
      id: 10,
      imgUrl: images.solarPortfolio2,
    },
    {
      id: 11,
      imgUrl: images.solarPortfolio3,
    },
  ];

  return (
    <div className="swiper-wrapper" id="d-slider">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        spaceBetween={10}
        loop={true}
        slidesPerView={3}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        breakpoints={{
          40: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        pagination={false}
        modules={[EffectCoverflow, Pagination, Autoplay]}
        className="mySwiper"
      >
        {projectSlide.map((item, id) => (
          <SwiperSlide>
            <ProjectCard imgUrl={item.imgUrl} key={id} />
          </SwiperSlide>
        ))}
        {/* {slideData &&
          slideData.map((item, id) => (
            <SwiperSlide>
              <ProjectCard
                imgUrl={item.featuredImage.node.sourceUrl}
                key={id}
              />
            </SwiperSlide>
          ))} */}
      </Swiper>
    </div>
  );
}
