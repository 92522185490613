import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../../utils";

function Section3() {
  const [sectionData, setSectionData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  iTManpowerPages(where: {id:478}) {
    nodes {
    title
    content
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(responseData.data.iTManpowerPages.nodes[0]);
        setSectionData(responseData.data.iTManpowerPages.nodes[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="bg-light" data-aos="fade-up" data-aos-duration="1000">
      <div className="container py-5 px-5">
        <div className="row">
          <div className="col-lg-12 align-items-center d-flex justify-content-center flex-column">
            {sectionData && (
              <>
                <h2>{sectionData.title}</h2>
                <div
                  dangerouslySetInnerHTML={{ __html: sectionData.content }}
                ></div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section3;
