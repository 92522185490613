import React, { useState } from "react";

function RightCard({ service, id }) {
  const [activeLayer, setActiveLayer] = useState(null);

  const handleMoreButtonClick = () => {
    setActiveLayer(id);
  };

  const handleBackButtonClick = () => {
    setActiveLayer(null);
  };
  return (
    <div className="col-sm-12 col-md-5 col-lg-4 p-0 right-tile" id="ai">
      <article
        className="position-relative rounded-5"
        data-aos="slide-left"
        data-aos-duration="1000"
      >
        <img
          src={service.featuredImage.node.sourceUrl}
          className="img-fluid rounded-5"
          alt=""
        />
        <div className="content rounded-5" data-aos="fade-up">
          {/* <h5 data-aos="fade-up">ERP BASED SOLUTIONS</h5> */}
          <h5 data-aos="fade-up">{service.allPageData.mainHeading}</h5>

          {/* <p data-aos="fade-up">Pioneer New Business Opportunities With ERP</p> */}
          <p data-aos="fade-up">{service.allPageData.subHeading}</p>

          <button
            className=" moreButton"
            data-aos="fade-up"
            onClick={() => {
              handleMoreButtonClick();
            }}
          >
            Read More
          </button>
        </div>
        <div
          className={`layer position-absolute d-flex flex-column align-items-start justify-content-center rounded-5 ${
            activeLayer === id ? "active" : ""
          }`}
        >
          <div dangerouslySetInnerHTML={{ __html: service.content }}></div>
          {/* <ul className=" tech-ul">
            <li>
              <span>
                <i className="fa-solid fa-circle"></i>
              </span>
              <span>
                Seamlessly integrates a plethora of departments and functions
                within an organization.
              </span>
            </li>
            <li>
              <span>
                <i className="fa-solid fa-circle"></i>
              </span>
              <span>
                Provide a centralized database and unified platform for
                effective data management.
              </span>
            </li>
            <li>
              <span>
                <i className="fa-solid fa-circle"></i>
              </span>
              <span>
                Automate routine tasks and processes, minimizing manual effort
                and maximizing core operational efficiency.
              </span>
            </li>
            <li>
              <span>
                <i className="fa-solid fa-circle"></i>
              </span>
              <span>
                Real-time reporting and analytics capabilities, enabling
                informed decision-making.
              </span>
            </li>
          </ul> */}
          <button
            className="backButton"
            onClick={() => {
              handleBackButtonClick();
            }}
          >
            Back
          </button>
        </div>
      </article>
    </div>
  );
}

export default RightCard;
