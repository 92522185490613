import React, { useState, useEffect } from "react";
import WhyCard from "../Cards/WhyCard";
import { BASE_URL } from "../../../utils";
function WhyUs() {
  const [topData, setTopData] = useState([]);
  const [bottomData, setBottomData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  solarPageSections(where: {termTaxonomyId: "37"}) {
    nodes {
      topdata: solarPages(where: {id: 480, orderby: {field: DATE, order: ASC}}) {
        nodes {
         
          allPageData {
            mainHeading
            subHeading
          }
        }
      }
      bottomdata: solarPages( where: {orderby: {field: DATE, order: ASC}, notIn: "480"} ) {
        nodes {
          title
          content
          featuredImage {
            node {
              sourceUrl
            }
          }
          infraSolar {
            smallImage {
              node {
                mediaItemUrl
              }
            }
            
          }
        }
      }
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(
        //   responseData.data.solarPageSections.nodes[0].bottomdata.nodes[0]
        // );
        setTopData(
          responseData.data.solarPageSections.nodes[0].topdata.nodes[0]
            .allPageData
        );
        setBottomData(
          responseData.data.solarPageSections.nodes[0].bottomdata.nodes
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <section className="we-offer dp solar-sec">
      <div className="container p-0">
        <div className="row justify-content-center">
          <div className="col-11 col-md-7 text-center">
            {topData && (
              <>
                <p className="main-heading m-0 text-center" data-aos="fade-up">
                  {topData.mainHeading}
                </p>
                <p
                  className="fw-bolder head-line fs-2 text-center"
                  data-aos="fade-up"
                >
                  {topData.subHeading}
                </p>
              </>
            )}
          </div>
        </div>

        <div className="row my-2 justify-content-center">
          {bottomData &&
            bottomData.map((item, id) => (
              <WhyCard
                key={id}
                title={item.title}
                desc={item.content}
                imgUrl={item.featuredImage.node.sourceUrl}
                iconUrl={item.infraSolar.smallImage.node.mediaItemUrl}
              />
            ))}
        </div>
      </div>
    </section>
  );
}

export default WhyUs;
