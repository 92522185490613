import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../../utils";

function Section2() {
  const [leftData, setLeftData] = useState([]);
  const [rightData, setRightData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  energyAdvisorySections(where: {termTaxonomyId: "15"}) {
    
    nodes{
    leftdata: energyAdvisoryPages(where: {id: 263, orderby: {field: DATE, order: ASC}}) {
        nodes {
          content
          featuredImage {
            node {
              sourceUrl
            }
          }
          allPageData {
            mainHeading
            subHeading
          }
        }
      }
    rightdata: energyAdvisoryPages(where: {orderby: {field: DATE, order: ASC}, notIn: "263"}) {
        nodes {
          content
        }
      }
         
        }
      } 
    }`,
          }),
        });
        const responseData = await response.json();
        // console.log(
        //   responseData.data.energyAdvisorySections.nodes[0].rightdata.nodes[0]
        // );
        setLeftData(
          responseData.data.energyAdvisorySections.nodes[0].leftdata.nodes[0]
        );
        setRightData(
          responseData.data.energyAdvisorySections.nodes[0].rightdata.nodes
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="py-5 px-2 px-sm-3 px-md-4">
      <div className="container p-0">
        <div className="row energy-advisory-section2-row">
          <div
            className="col-lg-6 col-md-12 energy-left-col mb-4"
            data-aos="slide-right"
            data-aos-duration="1000"
          >
            {leftData && leftData.allPageData && (
              <>
                <h3 className="sec2-content">
                  {leftData.allPageData.mainHeading} <br />{" "}
                  {leftData.allPageData.subHeading}
                </h3>
              </>
            )}
            <div dangerouslySetInnerHTML={{ __html: leftData.content }}></div>
            {leftData.featuredImage &&
              leftData.featuredImage.node &&
              leftData.featuredImage.node.sourceUrl && (
                <>
                  <img
                    src={leftData.featuredImage.node.sourceUrl}
                    alt=""
                    className="img-fluid"
                  />
                </>
              )}
          </div>
          <div
            className="col-lg-6 col-md-12 energy-right-col d-flex flex-column align-items-start"
            data-aos="slide-left"
            data-aos-duration="1000"
          >
            <div className="mb-3 d-flex justify-content-start align-items-start">
              <h3 className="sec2-content">Services We Offer</h3>
            </div>
            {rightData &&
              rightData.map((item, id) => (
                <div
                  className="mb-3 d-flex justify-content-start align-items-start"
                  key={id}
                >
                  {/* <i className="fa-solid fa-diamond"></i> */}
                  <h6
                    className="fs-6"
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  ></h6>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section2;
