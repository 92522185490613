import React, { useState, useEffect } from "react";
import StatsCard from "../Cards/StatsCard";
import { BASE_URL } from "../../../utils";

function Stats() {
  const [leftStats, setLeftStat] = useState([]);
  const [rightStats, setRightStat] = useState([]);

  // useEffect(() => {
  //   fetch(
  //     "https://dev04.kreatetechnologies.com/smartech-api/wp-json/custom/v1/home-below-why-choose"
  //   )
  //     .then((res) => {
  //       return res.json();
  //     })
  //     .then((data) => {
  //       // console.log("Stats data------", data);
  //       setRightStat(data["right-part"][0].thumbnail);
  //       setLeftStat(data["left-part"]);
  //       // setSlidesData(data);
  //     });
  // }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
    leftpart: homePageSections(where: {termTaxonomyId: "6"}) {
    nodes {
      homePages(where: {orderby: {field: DATE, order: ASC}, notIn: "141"}) {
        nodes {
          content
          homePageAchievments {
            count
            countTitle
          }
        }
      }
    }
  }
  rightpart: homePageSections(where: {termTaxonomyId: "6"}) {
    nodes {
      homePages(where: {id: 141, orderby: {field: DATE, order: ASC}}) {
        nodes {
          title
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(responseData.data.leftpart.nodes[0].homePages.nodes[0]);
        setLeftStat(responseData.data.leftpart.nodes[0].homePages.nodes);
        setRightStat(responseData.data.rightpart.nodes[0].homePages.nodes[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="sec4" data-aos="fade-up" data-aos-duration=" 1100">
      <div className="container p-0">
        <div className="row p-0 m-0">
          <div className="col-md-12 col-lg-8 d-flex justify-content-center align-items-center">
            <div className="items shadow rounded-3 flex-lg-wrap flex-xl-nowrap">
              {leftStats &&
                leftStats.map((item, id) => {
                  return (
                    <StatsCard
                      key={id}
                      count={item.homePageAchievments.count}
                      count_title={item.homePageAchievments.countTitle}
                      content={item.content}
                      lastClass={id === leftStats.length - 1 ? true : false}
                    />
                  );
                })}
            </div>
          </div>
          {rightStats &&
            rightStats.featuredImage &&
            rightStats.featuredImage.node && (
              <div className="col-4 my-md-4 my-0 sec4_right_col">
                <img
                  src={rightStats.featuredImage.node.sourceUrl}
                  alt={rightStats.title}
                  className="img-fluid section-4-image"
                />
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default Stats;
