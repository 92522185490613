import React from "react";

function ResultModal({ showModal, closeModal, modalData }) {
  return (
    <div
      className={`modal fade ${showModal ? "show d-block" : ""}`}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="staticBackdropLabel"
      aria-hidden={!showModal}
      style={{ backgroundColor: showModal ? "rgba(0,0,0,0.5)" : "transparent" }}
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Calculated Result
            </h5>
          </div>
          <div className="modal-body d-flex flex-column align-items-start justify-content-evenly gap-1 gap-sm-1 gap-md-3">
            <div className="row w-100 justify-content-between">
              <div className="col-md-12 col-lg-6 p-0">
                <div className="d-flex align-items-center justify-content-start gap-2">
                  <p className="solor-otput-value">Solar capacity:</p>
                  <p id="sc" className="mb-0">
                    {modalData.solarCapacity} kWp
                  </p>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 p-0">
                <div className="d-flex align-items-center justify-content-start gap-2">
                  <p className="solor-otput-value">Annual Generation:</p>
                  <p id="ag" className="mb-0">
                    {modalData.annualGeneration} kWh
                  </p>
                </div>
              </div>
            </div>
            <div className="row w-100 justify-content-between">
              <div className="col-md-12 col-lg-6 p-0">
                <div className="d-flex align-items-center justify-content-start gap-2">
                  <p className="solor-otput-value">Annual Saving:</p>
                  <p id="as" className="mb-0">
                    ₹{modalData.annualSaving}
                  </p>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 p-0">
                <div className="d-flex align-items-center justify-content-start gap-2">
                  <p className="solor-otput-value">
                    Expected Cost of the plant:
                  </p>
                  <p id="ec" className="mb-0">
                    ₹{modalData.expectedCost}
                  </p>
                </div>
              </div>
            </div>
            <div className="row w-100 justify-content-between">
              <div className="col-md-12 col-lg-6 p-0">
                <div className="d-flex align-items-center justify-content-start gap-2">
                  <p className="solor-otput-value">Return on investment:</p>
                  <p id="rv" className="mb-0">
                    {modalData.returnOnInvestment}
                  </p>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 p-0">
                <div className="d-flex align-items-center justify-content-start gap-2">
                  <p className="solor-otput-value">Address:</p>
                  <div>
                    <p className="mb-0" id="loc">
                      {modalData.address}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResultModal;
