import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../utils";

function Section1({ headings }) {
  const [sectionData, setSectionData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  consultancyDivisionPages(where: {id: 275}) {
    nodes {
      content
      allPageData {
        mainHeading
      }
    }
  }
}
`,
          }),
        });
        const responseData = await response.json();
        // console.log(responseData.data.consultancyDivisionPages.nodes[0]);
        setSectionData(responseData.data.consultancyDivisionPages.nodes[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <div className="conatiner about-banner d-flex align-items-center justify-content-center py-lg-5 ps-lg-5 ">
        <div className="row align-items-center justify-content-center p-lg-5 ">
          <div className=" col-12 col-md-12 col-lg-7 bannerLeft">
            <h1 className="mb-0 mb-lg-2 text-center">{headings.mainHeading}</h1>
            <h5 className="mb-0 mb-lg-2 text-center">{headings.subHeading}</h5>
            <p className="wrap p-0 m-0 my-lg-2 text-center">
              SmarTech offers the world the most appropriate way to go. As a
              leading-edge technology, renewable energy and energy consultancy
              mainstay, we are a hubspot for all major utilities you need to
              make a difference. Why Us? We understand goals, operational
              requirements and risk tolerance of our clients and extend
              thoroughgoing assistance in the development and implementation of
              comprehensive management solutions.
            </p>
            <div className="button-box mt-3 btn-sm d-none d-md-flex justify-content-center">
              <a className="smButton2" href="#services">
                Learn More
                <div className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="first-main-sec m-0 consult-sec2 bg-light ">
        <div className="container p-0 m-0">
          <div className="row d-flex align-items-center me-0">
            {sectionData && sectionData.allPageData && (
              <>
                <div className="col-lg-3 pe-0 pe-lg-0 pt-4 pt-lg-0">
                  <h3 className="sec2-content pb-0 mb-0">
                    {sectionData.allPageData.mainHeading}
                  </h3>
                </div>
                <div
                  className="col-lg-6 text-dark pt-lg-4 py-2 pe-3 ps-lg-0 ps-4 text-center"
                  dangerouslySetInnerHTML={{ __html: sectionData.content }}
                ></div>
              </>
            )}
            {/* <div className="col-lg-3 pe-0">
              <h3 className="sec2-content pt-lg-4 pt-2 pb-0">Yes We Do</h3>
            </div>
            <div className="col-lg-6 text-dark pt-lg-4 py-2 pe-3 ">
              <p>
                SmarTech provides top-notch energy consulting, technology,
                renewable energy and advisory solutions to commercial,
                industrial and institutional clients, including energy providers
                and other organisations engaged in the energy and IT domain.
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Section1;
