import React, { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./slides.css";
import SingleSlide from "./SingleSlide";
import { BASE_URL } from "../../../utils";

function Slides() {
  const [slidesData, setSlidesData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `
                           {
  homePageSections(where: {termTaxonomyId: "7"}) {
    nodes {
      id
      name
      homePages(where: {orderby: {field: DATE, order: ASC}}) {
        nodes {
          id
          title
          content
          featuredImage {
            node {
              sourceUrl
            }
          }
          homePagesSlider {
            pageUrl
          }
        }
      }
    }
  }
}
            `,
          }),
        });
        const responseData = await response.json();
        // console.log(
        //   responseData.data.homePageSections.nodes[0].homePages.nodes[0]
        // );
        setSlidesData(
          responseData.data.homePageSections.nodes[0].homePages.nodes
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="sec5-bg" data-aos="zoom-in-up" data-aos-duration="1000">
      <div className="container text-center d-flex align-items-center justify-content-center px-3">
        <div
          className="swiper mySwiper2 home-swiper-section py-0 py-lg-3"
          id="about-swiper"
        >
          <div className="swiper-wrapper">
            <Swiper
              spaceBetween={120}
              // centeredSlides={true}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Pagination, Navigation]}
              navigation={true}
              className="mySwiper2"
            >
              {slidesData.map((item, id) => {
                return (
                  <SwiperSlide key={id}>
                    <SingleSlide
                      img={item.featuredImage.node.sourceUrl}
                      heading={item.title}
                      description={item.content}
                      link={item.homePagesSlider.pageUrl}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Slides;
