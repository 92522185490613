import React from "react";
import OfficeDetails from "../OfficeDetails/OfficeDetails";
import ContactForm from "../ContactForm/ContactForm";

function Section() {
  return (
    <div className="ourtools_sec_bg mt-lg-5 " id="contact">
      <div className="container pt-60 pb-60">
        <div
          className="row contact-us d-flex align-items-center justify-content-center"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <ContactForm />
          <OfficeDetails />
        </div>
      </div>
    </div>
  );
}

export default Section;
