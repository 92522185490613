import React, { useEffect, useState } from "react";
import Card from "../Card/Card";
import { BASE_URL } from "../../../utils";
function Services() {
  const [heading, setHeading] = useState("");
  const [desc, setDesc] = useState("");
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  consultancyDivSections(where: {termTaxonomyId: "16"}) {    
    nodes{
    leftdata: consultancyDivisionPages(where: {id: 282, orderby: {field: DATE, order: ASC}}) {
        nodes {
          content
          allPageData {
            mainHeading
          }

        }
      }
    rightdata: consultancyDivisionPages(where: {orderby: {field: DATE, order: ASC}, notIn: "282"}) {
        nodes {
          content
          allPageData {
            mainHeading
            subHeading
            pageLink
          }
        }
      }
         
        }
      } 
    }
`,
          }),
        });
        const responseData = await response.json();
        // console.log(
        //   responseData.data.consultancyDivSections.nodes[0].rightdata.nodes[0]
        // );
        setHeading(
          responseData.data.consultancyDivSections.nodes[0].leftdata.nodes[0]
            .allPageData.mainHeading
        );
        setDesc(
          responseData.data.consultancyDivSections.nodes[0].leftdata.nodes[0]
            .content
        );
        setServices(
          responseData.data.consultancyDivSections.nodes[0].rightdata.nodes
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div id="services">
      <div data-aos="slide-left" data-aos-duration="1000">
        <div className="container">
          <div className="row py-4 py-lg-5 consult-sec4 d-flex align-items-center justify-content-between">
            <div className="col-lg-2 col-xlg-3">
              <h3 className="sec2-content">{heading}</h3>
            </div>
            <div
              className="col-lg-9 col-xlg-9"
              dangerouslySetInnerHTML={{ __html: desc }}
            ></div>
          </div>
        </div>
      </div>
      <div
        className="consultz_card_section"
        id="services-consult-sec"
        data-aos="zoom-in-up"
        data-aos-duration="1000"
      >
        <div className="container p-0">
          <div className="row justify-content-center">
            {services &&
              services.length > 0 &&
              services.map((item, id) => (
                <Card
                  key={id}
                  heading={item.allPageData.mainHeading}
                  subHeading={item.allPageData.subHeading}
                  description={item.content}
                  pageLink={item.allPageData.pageLink}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
