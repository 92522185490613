import React from "react";

function SingleSlide({ heading, description, img, link }) {
  return (
    <div className=" slider1 swiper-slide d-flex ">
      <div className="row justify-content-around">
        <div className="col-lg-5">
          <span className="home-slider-images">
            <img src={img} alt="" className="img-fluid" />
          </span>
        </div>
        <div className="col-lg-7">
          <span className="slider-span">
            <h2 className="about">{heading}</h2>
            <div className="para1 wrap">
              <div dangerouslySetInnerHTML={{ __html: description }}></div>

              {/* <p>{description}</p> */}
              <a className="button-box" href={link}>
                <button className="smButton2">
                  Read More
                  <div className="icon">
                    <svg
                      height="24"
                      width="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                </button>
              </a>
            </div>
          </span>
        </div>
      </div>
    </div>
  );
}

export default SingleSlide;
