import React from "react";
import Section from "../../components/Contact/Section/Section";
import Footer from "../../components/Common/Footer/Footer";
import Social from "../../components/Common/Social/Social";
import Chatbot from "../../components/Common/Chatbot/Chatbot";

import Header from "../../components/Contact/Header/Header";
function Contact() {
  return (
    <div
      className="container-fluid p-0"
      style={{ fontFamily: '"Montserrat", sans-serif' }}
    >
      <Header />
      <Section />
      <Footer />
      <Social />
      <Chatbot />
    </div>
  );
}

export default Contact;
