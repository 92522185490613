import React, { useEffect, useState } from "react";
import Navbar from "../../Common/Navbar/Navbar";
import HeaderCard from "../Cards/HeaderCard";
import { BASE_URL } from "../../../utils";

function Header() {
  const [headerData, setHeaderData] = useState([]);
  const [cardData, setCardData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  headersPages(where: {id:572}) {
    nodes {
     featuredImage {
            node {
              sourceUrl
            }
          }
           allPageData {
            mainHeading
            subHeading
          }
    }
  }
}
`,
          }),
        });
        const responseData = await response.json();
        // console.log(responseData.data.headersPages.nodes[0]);
        setHeaderData(responseData?.data?.headersPages?.nodes[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
headerSections(where: {termTaxonomyId: "42"}) {
    nodes {
      leftdata: headersPages(where: {id: 574, orderby: {field: DATE, order: ASC}}) {
        nodes {
          title
          content
        }
      }
      rightdata: headersPages(
        where: {orderby: {field: DATE, order: ASC}, notIn: "574"}
      ) {
       nodes {
                     title
        content
        allPageData {
            pageLink
          }
         featuredImage {
            node {
              sourceUrl
            }
          }
        }
    }
  }
}
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(responseData.data.headerSections.nodes[0]);
        setCardData(responseData?.data?.headerSections?.nodes[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    // headerData &&
    // headerData.featuredImage &&
    // headerData.featuredImage.node &&
    // headerData.allPageData &&
    // cardData &&
    // cardData.leftdata &&
    // cardData.leftdata.nodes &&
    // cardData.rightdata &&
    // cardData.rightdata.nodes && (
    <header>
      <div className="container-fluid p-0">
        <div
          className="second-banner d-flex flex-column justify-content-center align-items-center"
          style={{
            background:
              // headerData &&
              // headerData.featuredImage &&
              // headerData.featuredImage.node
              // ?
              `linear-gradient(290deg, rgba(0, 0, 0, 0.819), #439a7dbc), url(${headerData?.featuredImage?.node?.sourceUrl}) no-repeat 100%`,
            // : "",
          }}
        >
          <Navbar />
          <section className="mt-md-5 mt-lg-0">
            <div className="container-fluid Solar-img-cont dp ">
              <div className="row justify-content-center">
                <div className="col-11 col-md-7 text-center pt-4">
                  <h1 className="solar-power-heading pt-4  text-white">
                    {
                      // headerData.allPageData
                      //   ?
                      headerData?.allPageData?.mainHeading
                      // : ""
                    }
                  </h1>

                  <p className=" fw-bolder head-line text-white typing-text text-center ">
                    {
                      // headerData.allPageData
                      //   ?
                      headerData?.allPageData?.subHeading
                      // : ""
                    }
                  </p>
                </div>
              </div>
            </div>
          </section>
          <div className="second-banner-overlap dp mt-lg-5 mb-lg-3 ">
            <div className="row p-0 align-items-end justify-content-center ">
              <div className="col-md-12 col-lg-3 h-100">
                <div className="card text-center h-100 py-1 large-card">
                  <div className="card-body px-3 d-flex flex-column align-items-center justify-content-center">
                    <p className="text-center">
                      <i className="bi bi-lightning-charge-fill fs-3"></i>
                    </p>
                    <h5 className="card-title mb-2 fw-bold">
                      {
                        // cardData && cardData.leftdata && cardData.leftdata.nodes
                        //   ?
                        cardData?.leftdata?.nodes[0]?.title
                        // : ""
                      }
                    </h5>
                    <div
                      className="mb-3"
                      dangerouslySetInnerHTML={{
                        __html:
                          // cardData &&
                          // cardData.leftdata &&
                          // cardData.leftdata.nodes
                          // ?
                          cardData?.leftdata?.nodes[0]?.content,
                        // : "",
                      }}
                    ></div>
                    <button className="custom-btn border-0 bg-light">
                      <a
                        href="#solarCalculator"
                        className="text-decoration-none text-dark"
                      >
                        Calculate
                      </a>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-8 my-3 my-lg-0">
                <div className="row flex-wrap row-gap-3">
                  <HeaderCard
                    title={cardData?.rightdata?.nodes[0]?.title}
                    desc={cardData?.rightdata?.nodes[0]?.content}
                    icon={
                      cardData?.rightdata?.nodes[0]?.featuredImage?.node
                        ?.sourceUrl
                    }
                    link={cardData?.rightdata?.nodes[0]?.allPageData?.pageLink}
                  />
                  <HeaderCard
                    title={cardData?.rightdata?.nodes[1]?.title}
                    desc={cardData?.rightdata?.nodes[1]?.content}
                    icon={
                      cardData?.rightdata?.nodes[1]?.featuredImage?.node
                        ?.sourceUrl
                    }
                    link={cardData?.rightdata?.nodes[1]?.allPageData?.pageLink}
                  />
                  <HeaderCard
                    title={cardData?.rightdata?.nodes[2]?.title}
                    desc={cardData?.rightdata?.nodes[2]?.content}
                    icon={
                      cardData?.rightdata?.nodes[2]?.featuredImage?.node
                        ?.sourceUrl
                    }
                    link={cardData?.rightdata?.nodes[2]?.allPageData?.pageLink}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    // )
  );
}

export default Header;
