import React, { useState, useEffect } from "react";
import Slide from "./Slide";
import { BASE_URL } from "../../../utils";
function Customers() {
  const [data, setData] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{ 
  homePageSections(where: {termTaxonomyId: "8"}) {
    nodes {
      name
    }
  }
}
`,
          }),
        });
        const responseData = await response.json();
        // console.log(responseData.data.homePageSections.nodes[0]);
        setData(responseData.data.homePageSections.nodes[0].name);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="bg-section" data-aos="fade-up" data-aos-duration="1000">
      <div className="container text-center section6">
        {data && (
          <h2 className="blue fw-bold sec2-content pt-4 mb-0 mb-md-3">
            {data}
          </h2>
        )}
        {/* <h2 className="blue fw-bold sec2-content pt-4 mb-0 mb-md-3">
          Our Customers
        </h2> */}
        <div className="d-flex flex-wrap justify-content-center gap-4">
          <div className="swiper mySwiper ">
            <Slide />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Customers;
