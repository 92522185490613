import React from "react";
import Section2 from "../../components/AboutPage/Section2/Section2";
import Footer from "../../components/Common/Footer/Footer";
import Section3 from "../../components/AboutPage/Section3/Section3";
// import Team from "../../components/AboutPage/Team/Team";
import Social from "../../components/Common/Social/Social";
import Chatbot from "../../components/Common/Chatbot/Chatbot";

import Header from "../../components/AboutPage/Header/Header";
import { log } from "../../utils";

function About() {
  return (
    <div
      style={{
        fontFamily: '"Montserrat", sans-serif',
        backgroundColor: "#eee",
      }}
    >
      <Header />
      <Section2 />
      <Section3 />
      {/* <Team /> */}
      <Footer />
      <Social />
      <Chatbot />
    </div>
  );
}

export default About;
