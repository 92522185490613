import React from "react";
import Header from "../../components/HomePage/Header/Header";
import Features from "../../components/HomePage/Features/Features";
import WhyUs from "../../components/HomePage/WhyUs/WhyUs";
import Stats from "../../components/HomePage/Stats/Stats";
import Slides from "../../components/HomePage/Slides/Slides";
import Customers from "../../components/HomePage/Customers/Customers";
import Footer from "../../components/Common/Footer/Footer";
import Social from "../../components/Common/Social/Social";
import Chatbot from "../../components/Common/Chatbot/Chatbot";

export default function Home() {
  return (
    <div
      style={{
        fontFamily: '"Montserrat", sans-serif',
        backgroundColor: "#eee",
      }}
    >
      <Header />
      <Features />
      <WhyUs />
      <Stats />
      <Slides />
      <Customers />
      <Footer />
      <Social />
      <Chatbot />
    </div>
  );
}
