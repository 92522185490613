import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BASE_REST_URL, BASE_URL } from "../../../utils";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  // const [icon, setIcon] = useState("");
  const [menuData, setMenuData] = useState([]);

  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(BASE_URL, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           query: `{
  //   headersPages(where: {id:613}) {
  //     nodes {
  //      featuredImage {
  //             node {
  //               sourceUrl
  //             }
  //           }
  //     }
  //   }
  // }`,
  //         }),
  //       });
  //       const responseData = await response.json();
  //       setIcon(
  //         responseData.data.headersPages.nodes[0].featuredImage.node.sourceUrl
  //       );
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };
  const fetchMenu = async () => {
    try {
      const response = await fetch(BASE_REST_URL);
      const responseData = await response.json();
      // console.log(JSON.stringify(responseData.Navbar));
      setMenuData(responseData.Navbar);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    // fetchData();
    fetchMenu();
  }, []);
  const handleMouseEnter = (menuId, submenuId) => {
    setActiveMenu(menuId);
    setActiveSubMenu(submenuId);
  };

  const handleMouseLeave = () => {
    setActiveMenu(null);
    setActiveSubMenu(null);
  };
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <nav className="navbarSm container-fluid d-flex flex-wrap flex-lg-nowrap align-items-md-center align-items-lg-center justify-content-between">
      <Link to="/">
        <p style={{ fontSize: 19, width: 190, margin: 0, fontWeight: "500" }}>
          Smartech Advisory
        </p>
        {/* <img
          src={icon}
          alt="deskotop_logo"
          className="img-fluid ps-4 navLogo"
        /> */}
      </Link>
      <ul
        className={`navList w-100 p-0 m-0 d-flex flex-column flex-lg-row justify-content-md-start justify-content-lg-center align-items-center order-3 order-lg-2 ${
          isMenuOpen ? "navOpen" : ""
        }`}
      >
        {menuData.map((menuItem) => (
          <li
            key={menuItem.id}
            className={`nav-item ${
              menuItem.submenu.length > 0 ? "dropdown" : ""
            }`}
            onMouseEnter={() => handleMouseEnter(menuItem.id, null)}
            onMouseLeave={handleMouseLeave}
          >
            <a
              href={menuItem.route}
              className={`navLink ${
                menuItem.submenu.length > 0 ? "dropdownLink" : ""
              }`}
            >
              {menuItem.title}
            </a>
            {menuItem.submenu.length > 0 && activeMenu === menuItem.id && (
              <ul className="dropdown-menu">
                {menuItem.submenu.map((subMenuItem) => (
                  <li
                    key={subMenuItem.id}
                    className={`dropdown-item ${
                      subMenuItem.submenu.length > 0 ? "dropdown-submenu" : ""
                    }`}
                    onMouseEnter={() =>
                      handleMouseEnter(menuItem.id, subMenuItem.id)
                    }
                    onMouseLeave={handleMouseLeave}
                  >
                    <a
                      className={`${
                        menuItem.submenu.length > 0 ? "dropdownLink" : ""
                      }`}
                      href={subMenuItem.route}
                    >
                      {subMenuItem.title}
                    </a>
                    {subMenuItem.submenu.length > 0 &&
                      activeSubMenu === subMenuItem.id && (
                        <ul className="dropdown-menu">
                          {subMenuItem.submenu.map((nestedMenuItem) => (
                            <li
                              key={nestedMenuItem.id}
                              className="dropdown-item"
                            >
                              <a href={nestedMenuItem.route}>
                                {/* <span style={{ color: "black" }}> */}
                                {nestedMenuItem.title}
                                {/* </span> */}
                              </a>
                            </li>
                          ))}
                        </ul>
                      )}
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
      <div
        className={`navToggle position-relative d-block d-lg-none pe-3 ${
          isMenuOpen ? "open" : ""
        }`}
        id="menu"
        onClick={toggleMenu}
      >
        <div className="top-bar"></div>
        <svg
          width="40"
          height="40"
          viewBox="0 0 84 84"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="mid-bar"
            fill="none"
            strokeWidth="4px"
            strokeMiterlimit="10"
            d="M22,42h40 c5,0, 20, -10, 14.641,-20 A40,40, 0 0 0 42 2  A40,40, 0 0 0 2 42  A40 40 0 0 0 42 82 A40 40 0 0 0 82 42 A40 40 0 0 0 42 2 A40 40 0 0 0 2 42"
          />
        </svg>
        <div className="btm-bar"></div>
      </div>
    </nav>
  );
};

export default Navbar;

// import React, { useState, useEffect } from "react";
// import { BASE_REST_URL } from "../../../utils";

// function Navbar() {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const [icon, setIcon] = useState("");
//   const [menuData, setMenuData] = useState([]);

//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   const hoverMenu = (e) => {
//     let link = e.currentTarget;
//     let dropdown = link.nextElementSibling;

//     if (window.innerWidth >= 993) {
//       dropdown.classList.add("showDropdown");
//       link.classList.add("focused");

//       dropdown.addEventListener("mouseleave", () => {
//         dropdown.classList.remove("showDropdown");
//         link.classList.remove("focused");
//       });
//     }
//   };

//   const clickMenu = (e) => {
//     let link = e.currentTarget;
//     let dropdown = link.nextElementSibling;

//     if (window.innerWidth < 992) {
//       dropdown.classList.toggle("showDropdown");

//       document.addEventListener("click", (event) => {
//         if (!link.contains(event.target) && !dropdown.contains(event.target)) {
//           dropdown.classList.remove("showDropdown");
//         }
//       });
//     }
//   };
//   const fetchData = async () => {
//     try {
//       const response = await fetch("/smartech-api/graphql", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           query: `{
//   headersPages(where: {id:613}) {
//     nodes {
//      featuredImage {
//             node {
//               sourceUrl
//             }
//           }
//     }
//   }
// }`,
//         }),
//       });
//       const responseData = await response.json();
//       // console.log(
//       //   responseData.data.headersPages.nodes[0].featuredImage.node.sourceUrl
//       // );
//       setIcon(
//         responseData.data.headersPages.nodes[0].featuredImage.node.sourceUrl
//       );
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };
//   const fetchMenu = async () => {
//     try {
//       const response = await fetch(BASE_REST_URL);
//       const responseData = await response.json();
//       console.log(responseData.Navbar);
//       setMenuData(responseData.Navbar);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };
//   useEffect(() => {
//     fetchData();
//     fetchMenu();
//   }, []);
//   useEffect(() => {
//     const handleMouseLeave = () => {
//       // Remove the showDropdown class and focused class
//       const dropdowns = document.querySelectorAll(".dropdownMenu");
//       const links = document.querySelectorAll(".dropdownLink");

//       dropdowns.forEach((dropdown) => {
//         dropdown.classList.remove("showDropdown");
//       });

//       links.forEach((link) => {
//         link.classList.remove("focused");
//       });
//     };

//     const dropdowns = document.querySelectorAll(".dropdownMenu");
//     dropdowns.forEach((dropdown) => {
//       // Add event listener to each dropdown menu
//       dropdown.addEventListener("mouseleave", handleMouseLeave);
//     });

//     // Cleanup function to remove event listeners
//     return () => {
//       dropdowns.forEach((dropdown) => {
//         dropdown.removeEventListener("mouseleave", handleMouseLeave);
//       });
//     };
//   }, []);
//   return (
//     <nav className="navbarSm container-fluid d-flex flex-wrap flex-lg-nowrap align-items-md-start align-items-lg-center justify-content-between">
//       <a href="/">
//         {/* <img src={icon} alt="" className="img-fluid ps-4 navLogo" /> */}
//         <p>Smartech Advisory</p>
//       </a>
//       <ul
//         className={`navList w-100 p-0 m-0 d-flex flex-column flex-lg-row justify-content-md-start justify-content-lg-center align-items-center order-3 order-lg-2 ${
//           isMenuOpen ? "navOpen" : ""
//         }`}
//       >
//         {menuData.map((item) => (
//           <li key={item.id} className="navItem">
//             <a
//               className={`navLink ${
//                 item.submenu.length > 0 ? "dropdownLink" : ""
//               }`}
//               onClick={item.submenu.length > 0 ? clickMenu : undefined}
//               onMouseOver={item.submenu.length > 0 ? hoverMenu : undefined}
//               href={item.route}
//             >
//               {item.title}
//             </a>
//             {item.submenu.length > 0 && (
//               <ul className="dropdownMenu">
//                 {item.submenu.map((subItem) => (
//                   <React.Fragment key={subItem.id}>
//                     {subItem.submenu.length > 0 ? (
//                       <li className="menuItem d-flex flex-column justify-content-center align-items-center">
//                         <a
//                           className="submenuLink dropdownLink"
//                           onClick={clickMenu}
//                           onMouseOver={hoverMenu}
//                           href={subItem.route}
//                         >
//                           {subItem.title}
//                         </a>
//                         <ul className="dropdownSubMenu">
//                           {subItem.submenu.map((ele) => (
//                             <li key={ele.id} className="subMenuItem">
//                               <a className="dropdownLink" href={ele.route}>
//                                 {ele.title}
//                               </a>
//                             </li>
//                           ))}
//                         </ul>
//                       </li>
//                     ) : (
//                       <li key={subItem.id} className="menuItem">
//                         <a className="dropdownLink" href={subItem.route}>
//                           {subItem.title}
//                         </a>
//                       </li>
//                     )}
//                   </React.Fragment>
//                 ))}
//               </ul>
//             )}
//           </li>
//         ))}
//       </ul>
//       <div
//         className={`navToggle position-relative d-block d-lg-none pe-3 ${
//           isMenuOpen ? "open" : ""
//         }`}
//         id="menu"
//         onClick={toggleMenu}
//       >
//         <div className="top-bar"></div>
//         <svg
//           width="40"
//           height="40"
//           viewBox="0 0 84 84"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             className="mid-bar"
//             fill="none"
//             strokeWidth="4px"
//             strokeMiterlimit="10"
//             d="M22,42h40 c5,0, 20, -10, 14.641,-20 A40,40, 0 0 0 42 2  A40,40, 0 0 0 2 42  A40 40 0 0 0 42 82 A40 40 0 0 0 82 42 A40 40 0 0 0 42 2 A40 40 0 0 0 2 42"
//           />
//         </svg>
//         <div className="btm-bar"></div>
//       </div>
//     </nav>
//   );
// }
// export default Navbar;
