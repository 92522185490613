import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../../utils";

function SalesContact({ mail }) {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{ 
  footerPages(where: {id: 604}) {
    nodes {
      content
      allPageData {
            mainHeading
            subHeading
            pageLink
          }
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(responseData.data.footerPages.nodes[0].allPageData);
        setData(responseData.data.footerPages.nodes[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div
      className="col-12 col-md-6 my-4"
      data-aos="slide-right"
      data-aos-duration="800"
    >
      <div
        className="card p-4 d-flex justify-content-center"
        style={{ height: "100%" }}
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        {data && data.allPageData && (
          <>
            <p className="main-heading m-0" data-aos="fade-up">
              {data.allPageData.mainHeading}
            </p>
            <p className="fw-bolder fs-3 contact-text m-0" data-aos="fade-up">
              {data.allPageData.subHeading}
            </p>
          </>
        )}
        {/* {data && <div dangerouslySetInnerHTML={{ __html: data.content }}></div>} */}
        <p className="pe-5" data-aos="fade-up">
          Let’s connect. We’d love to see the best we can do for your solar
          business.
        </p>
        <p className="fw-bolder fs-5" data-aos="fade-up">
          {mail}
        </p>

        <div className="row" data-aos="fade-up">
          <div className="col-10 col-md-7 p-0">
            <button className="smButton smcustom">
              Online Support
              <div className="icon">
                <svg
                  height="24"
                  width="24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesContact;
