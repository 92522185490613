import React from "react";
import Social from "../../components/Common/Social/Social";
import Header from "../../components/Technology/Header/Header";
import Support from "../../components/Common/Support/Support";
import Footer from "../../components/Common/Footer/Footer";
import Chatbot from "../../components/Common/Chatbot/Chatbot";

import Section1 from "../../components/Technology/Sections/Section1";
import Section2 from "../../components/Technology/Sections/Section2";
import Section3 from "../../components/Technology/Sections/Section3";

function Technology() {
  return (
    <div style={{ fontFamily: '"Montserrat", sans-serif' }}>
      <Header />
      <Section1 />
      <Section2 />
      <Section3 />
      <Support mail="sales@smartechadvisory.com" />
      <Footer />
      <Social />
      <Chatbot />
    </div>
  );
}

export default Technology;
