import React from "react";

function ProjectCard({ imgUrl }) {
  return (
    <div className="swiper-slide trending-slide">
      <div className="trending-slide-img">
        <img src={imgUrl} className="img-fluid" alt="" />
      </div>
    </div>
  );
}

export default ProjectCard;
