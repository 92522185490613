import React from "react";
import Header from "../../components/SerConsultancy/Header/Header";
import Footer from "../../components/Common/Footer/Footer";
import Social from "../../components/Common/Social/Social";
import Chatbot from "../../components/Common/Chatbot/Chatbot";
import Section3 from "../../components/SerConsultancy/Sections/Section3";
import Section1 from "../../components/SerConsultancy/Sections/Section1";
import Section2 from "../../components/SerConsultancy/Sections/Section2";

function SerConsultancy() {
  return (
    <div style={{ fontFamily: '"Montserrat", sans-serif' }}>
      <Header />
      <div style={{ backgroundColor: "#eee" }}>
        <Section1 />
        <Section2 />
      </div>
      <Section3 />
      <Footer />
      <Social />
      <Chatbot />
    </div>
  );
}

export default SerConsultancy;
