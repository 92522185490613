import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../../utils";
import { images } from "../../../res/images";

function Section1() {
  const [topData, setTopData] = useState([]);
  var width = window.innerWidth;

  useEffect(() => {
    width = window.innerWidth;
  }, [width]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  meteringSolutionsPages(where: {id: 528}) {  
 nodes {
         title
        featuredImage {
            node {
              sourceUrl
            }
          }
      }
  }
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(responseData.data.meteringSolutionsPages.nodes[0]);
        setTopData(responseData.data.meteringSolutionsPages.nodes[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="container text-start my-md-5 my-3" id="resp-sec">
      {width > 1000 ? (
        <>
          <div className="row my-5 resp-sec">
            <div className="col-lg-12 col-12 text-center position-relative">
              {topData &&
                topData.featuredImage &&
                topData.featuredImage.node && (
                  <img
                    src={topData.featuredImage.node.sourceUrl}
                    alt=""
                    className="img-fluid whyImg"
                  />
                )}
              <div className="metering-section1-para">
                {topData && (
                  <h3 className="sec2-content" id="metering-h3">
                    {topData.title}
                  </h3>
                )}
                <div id="metering-3">
                  <p>Improved security</p>
                </div>
                <div id="metering-6" data-aos="fade-up" data-aos-duration="600">
                  <p>
                    More efficient <br />
                    transmission of electricity
                  </p>
                </div>
                <div id="metering-1" data-aos="fade-up" data-aos-duration="600">
                  <p>
                    Increased integration of <br />
                    large-scale renewable
                    <br />
                    energy systems
                  </p>
                </div>
                <div id="metering-5" data-aos="fade-up" data-aos-duration="600">
                  <p>
                    Quicker restoration of electricity <br />
                    after power disturbances
                  </p>
                </div>
                <div id="metering-4" data-aos="fade-up" data-aos-duration="600">
                  <p>
                    Reduced operations and management <br />
                    costs for utilities, and ultimately <br />
                    lower power costs for consumers
                  </p>
                </div>
                <div id="metering-2" data-aos="fade-up" data-aos-duration="600">
                  <p>
                    Better integration of <br />
                    customer-owner power <br />
                    generation systems, including <br />
                    renewable energy systems
                  </p>
                </div>
                <div id="metering-7" data-aos="fade-up" data-aos-duration="600">
                  <p>
                    Reduced peak demand, <br />
                    which also helps to <br />
                    lower electricity purchase <br />
                    rates
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row my-5 resp-sec2">
            <div
              className="col-12 col-sm-6 resp-sec-tiles"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img src={images.group126} alt="" />
              <p>Quicker restoration of electricity after power disturbances</p>
            </div>
            <div
              className="col-12 col-sm-6 resp-sec-tiles"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img src={images.group127} alt="" />
              <p>
                Increased integration of large-scale renewable energy systems
              </p>
            </div>
            <div
              className="col-12 col-sm-6 resp-sec-tiles"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img src={images.group128} alt="" />
              <p>
                Reduced peak demand, which also helps to lower electricity
                purchase rates
              </p>
            </div>
            <div
              className="col-12 col-sm-6 resp-sec-tiles"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img src={images.group129} alt="" />
              <p>
                Reduced operations and management costs for utilities, and
                ultimately lower power costs for consumers
              </p>
            </div>
            <div
              className="col-12 col-sm-6 resp-sec-tiles"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img src={images.group130} alt="" />
              <p>Improved security</p>
            </div>
            <div
              className="col-12 col-sm-6 resp-sec-tiles"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img src={images.group131} alt="" />
              <p>
                Better integration of customer-owner power generation systems,
                including renewable energy systems
              </p>
            </div>
            <div
              className="col-12 col-sm-6 resp-sec-tiles"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img src={images.group132} alt="" />
              <p>More efficient transmission of electricity</p>
            </div>
          </div>
        </>
      )}
      {/* <div className="row my-5 resp-sec">
        <div className="col-lg-12 col-12 text-center position-relative">
          {topData && topData.featuredImage && topData.featuredImage.node && (
            <img
              src={topData.featuredImage.node.sourceUrl}
              alt=""
              className="img-fluid whyImg"
            />
          )}
          <div className="metering-section1-para">
            {topData && (
              <h3 className="sec2-content" id="metering-h3">
                {topData.title}
              </h3>
            )}
            <div id="metering-3">
              <p>Improved security</p>
            </div>
            <div id="metering-6" data-aos="fade-up" data-aos-duration="600">
              <p>
                More efficient <br />
                transmission of electricity
              </p>
            </div>
            <div id="metering-1" data-aos="fade-up" data-aos-duration="600">
              <p>
                Increased integration of <br />
                large-scale renewable
                <br />
                energy systems
              </p>
            </div>
            <div id="metering-5" data-aos="fade-up" data-aos-duration="600">
              <p>
                Quicker restoration of electricity <br />
                after power disturbances
              </p>
            </div>
            <div id="metering-4" data-aos="fade-up" data-aos-duration="600">
              <p>
                Reduced operations and management <br />
                costs for utilities, and ultimately <br />
                lower power costs for consumers
              </p>
            </div>
            <div id="metering-2" data-aos="fade-up" data-aos-duration="600">
              <p>
                Better integration of <br />
                customer-owner power <br />
                generation systems, including <br />
                renewable energy systems
              </p>
            </div>
            <div id="metering-7" data-aos="fade-up" data-aos-duration="600">
              <p>
                Reduced peak demand, <br />
                which also helps to <br />
                lower electricity purchase <br />
                rates
              </p>
            </div>
          </div>
        </div>
      </div> */}
      {window.innerWidth < 1000 && (
        <>
          <div className="row my-5 resp-sec2">
            <div
              className="col-12 col-sm-6 resp-sec-tiles"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img src={images.group126} alt="" />
              <p>Quicker restoration of electricity after power disturbances</p>
            </div>
            <div
              className="col-12 col-sm-6 resp-sec-tiles"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img src={images.group127} alt="" />
              <p>
                Increased integration of large-scale renewable energy systems
              </p>
            </div>
            <div
              className="col-12 col-sm-6 resp-sec-tiles"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img src={images.group128} alt="" />
              <p>
                Reduced peak demand, which also helps to lower electricity
                purchase rates
              </p>
            </div>
            <div
              className="col-12 col-sm-6 resp-sec-tiles"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img src={images.group129} alt="" />
              <p>
                Reduced operations and management costs for utilities, and
                ultimately lower power costs for consumers
              </p>
            </div>
            <div
              className="col-12 col-sm-6 resp-sec-tiles"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img src={images.group130} alt="" />
              <p>Improved security</p>
            </div>
            <div
              className="col-12 col-sm-6 resp-sec-tiles"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img src={images.group131} alt="" />
              <p>
                Better integration of customer-owner power generation systems,
                including renewable energy systems
              </p>
            </div>
            <div
              className="col-12 col-sm-6 resp-sec-tiles"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img src={images.group132} alt="" />
              <p>More efficient transmission of electricity</p>
            </div>
          </div>
        </>
      )}
      {/* <div className="row my-5 resp-sec2">
        <div
          className="col-12 col-sm-6 resp-sec-tiles"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <img src={images.group126} alt="" />
          <p>Quicker restoration of electricity after power disturbances</p>
        </div>
        <div
          className="col-12 col-sm-6 resp-sec-tiles"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <img src={images.group127} alt="" />
          <p>Increased integration of large-scale renewable energy systems</p>
        </div>
        <div
          className="col-12 col-sm-6 resp-sec-tiles"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <img src={images.group128} alt="" />
          <p>
            Reduced peak demand, which also helps to lower electricity purchase
            rates
          </p>
        </div>
        <div
          className="col-12 col-sm-6 resp-sec-tiles"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <img src={images.group129} alt="" />
          <p>
            Reduced operations and management costs for utilities, and
            ultimately lower power costs for consumers
          </p>
        </div>
        <div
          className="col-12 col-sm-6 resp-sec-tiles"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <img src={images.group130} alt="" />
          <p>Improved security</p>
        </div>
        <div
          className="col-12 col-sm-6 resp-sec-tiles"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <img src={images.group131} alt="" />
          <p>
            Better integration of customer-owner power generation systems,
            including renewable energy systems
          </p>
        </div>
        <div
          className="col-12 col-sm-6 resp-sec-tiles"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <img src={images.group132} alt="" />
          <p>More efficient transmission of electricity</p>
        </div>
      </div> */}
    </div>
  );
}

export default Section1;
