import React, { useState } from "react";
import { BASE_FORM_URL } from "../../../utils";

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.subject) newErrors.subject = "Subject is required";
    if (!formData.message) newErrors.message = "Message is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id.replace("input-", "")]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const data = new FormData();
      data.append("name", formData.name);
      data.append("email", formData.email);
      data.append("subject", formData.subject);
      data.append("message", formData.message);

      // console.log(Object.fromEntries(data));

      try {
        const response = await fetch(BASE_FORM_URL, {
          method: "POST",
          body: data,
        });
        if (response.ok) {
          // console.log("Success:", response);
          setFormData({
            name: "",
            email: "",
            subject: "",
            message: "",
          });
        }
      } catch (error) {
        console.error("Error:", error);
      }
      setFormData({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
    }
  };

  return (
    <div
      className="col-lg-6 col-md-6 col-sm-12"
      data-aos="slide-right"
      data-aos-duration="1100"
    >
      <form className="cf" onSubmit={handleSubmit}>
        <h2 className="text-start">
          <a
            href=""
            className="text-left fw-bold ourtools_head pb-3 effect-underline ourtools_head"
          >
            Let's Connect
          </a>
        </h2>

        <div>
          <label htmlFor="input-name">
            Name<span className="text-danger">*</span>
          </label>
          <input
            type="text"
            id="input-name"
            placeholder="Name"
            required
            className="form-control"
            value={formData.name}
            onChange={handleChange}
          />
          {errors.name && <div className="text-danger">{errors.name}</div>}

          <label htmlFor="input-email">
            Email<span className="text-danger">*</span>
          </label>
          <input
            type="email"
            id="input-email"
            placeholder="Email address"
            required
            className="form-control"
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && <div className="text-danger">{errors.email}</div>}

          <label htmlFor="input-subject">
            Subject<span className="text-danger">*</span>
          </label>
          <input
            type="text"
            id="input-subject"
            placeholder="Subject"
            required
            className="form-control"
            value={formData.subject}
            onChange={handleChange}
          />
          {errors.subject && (
            <div className="text-danger">{errors.subject}</div>
          )}

          <label htmlFor="input-message">
            Message<span className="text-danger">*</span>
          </label>
          <textarea
            name="message"
            id="input-message"
            placeholder="Message"
            required
            className="form-control"
            value={formData.message}
            onChange={handleChange}
          ></textarea>
          {errors.message && (
            <div className="text-danger">{errors.message}</div>
          )}
        </div>

        <button className="sendButton mt-3" type="submit">
          Submit
        </button>
      </form>
    </div>
  );
}

export default ContactForm;
