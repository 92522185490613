import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../utils";

function Section2() {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  iTServicePages(where: {id:427}) {
    nodes {
      content
    allPageData {
            mainHeading
          }
    }
  }
}
`,
          }),
        });
        const responseData = await response.json();
        // console.log(responseData.data.iTServicePages.nodes[0]);
        setData(responseData.data.iTServicePages.nodes[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="endNote" data-aos="fade-up" data-aos-duration="800">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 d-flex flex-column  align-items-center justify-content-center p-2">
            <h4>
              {data.allPageData && data.allPageData.mainHeading
                ? data.allPageData.mainHeading
                : ""}
            </h4>
            <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
            {/* <p>
              We want to work with you to turn all your "what could’ve been"
              into what is.
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section2;
