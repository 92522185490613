import React from "react";
import Support from "../../components/Common/Support/Support";
import Footer from "../../components/Common/Footer/Footer";
import Chatbot from "../../components/Common/Chatbot/Chatbot";

import Social from "../../components/Common/Social/Social";
import Section2 from "../../components/EnergyAdvisory/Section2/Section2";
import Section1 from "../../components/EnergyAdvisory/Section1/Section1";
import Header from "../../components/EnergyAdvisory/Header/Header";

function EnergyAdvisory() {
  return (
    <div
      style={{
        fontFamily: '"Montserrat", sans-serif',
        backgroundColor: "#eee",
      }}
    >
      <Header />
      <Section1 />
      <Section2 />
      <Support mail="sales@smartechadvisory.com" id="contactFormSection" />
      <Footer />
      <Social />
      <Chatbot />
    </div>
  );
}

export default EnergyAdvisory;
