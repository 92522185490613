import React from "react";
import Footer from "../../components/Common/Footer/Footer";
import Social from "../../components/Common/Social/Social";
import Chatbot from "../../components/Common/Chatbot/Chatbot";
import Header from "../../components/SerManpower/Header/Header";
import Section3 from "../../components/SerManpower/Sections/Section3";
import Section1 from "../../components/SerManpower/Sections/Section1";
import Section2 from "../../components/SerManpower/Sections/Section2";
import CardsSection from "../../components/SerManpower/Sections/CardsSection";

function SerManpower() {
  return (
    <div className="wrapper" style={{ fontFamily: '"Montserrat", sans-serif' }}>
      <Header />
      <div style={{ backgroundColor: "#eee" }}>
        <CardsSection />
        <Section1 />
        <Section2 />
      </div>
      <Section3 />
      <Footer />
      <Social />
      <Chatbot />
    </div>
  );
}

export default SerManpower;
