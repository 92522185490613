import React from "react";

function FeatureCard({ imgUrl, title, desc }) {
  return (
    <div className="boxStyle">
      <div className="imgBox">
        <img src={imgUrl} alt="" />
      </div>
      <div className="boxContent">
        <h5>{title}</h5>
        <div dangerouslySetInnerHTML={{ __html: desc }}></div>
      </div>
    </div>
  );
}

export default FeatureCard;
