import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import { Autoplay } from "swiper/modules";
import SingleSlide from "./SingleSlide";
import { BASE_URL } from "../../../utils";

function Slide() {
  const [slideData, setSlideData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{ 
  aboutPageSections(where: {termTaxonomyId: "10"}) {
    nodes {
      aboutPages(where: {orderby: {field: DATE, order: ASC}}) {
        nodes {
          title
          allPageData{
            pageLink
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(
        //   responseData.data.aboutPageSections.nodes[0].aboutPages.nodes[1]
        //     .allPageData.pageLink
        // );
        setSlideData(
          responseData.data.aboutPageSections.nodes[0].aboutPages.nodes
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="row">
      <div className="col-md-12 col-sm-12 p-0 text-center">
        <div className="swiper-container mySwiper pt-0">
          <Swiper
            spaceBetween={10}
            slidesPerView={3}
            // centeredSlides={true}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              200: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              320: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              480: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1400: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            modules={[Autoplay]}
            className="swiper-wrapper text-center"
          >
            {slideData &&
              slideData.length > 0 &&
              slideData.map((item, id) => (
                <SwiperSlide className="swiper-slide" key={id}>
                  <SingleSlide
                    title={item.title}
                    imgUrl={
                      item.featuredImage.node.sourceUrl
                        ? item.featuredImage.node.sourceUrl
                        : null
                    }
                    link={
                      item.allPageData.pageLink
                        ? item.allPageData.pageLink
                        : null
                    }
                  />
                </SwiperSlide>
              ))}
            {slideData &&
              slideData.length > 0 &&
              slideData.map((item, id) => (
                <SwiperSlide className="swiper-slide" key={id}>
                  <SingleSlide
                    title={item.title}
                    imgUrl={
                      item.featuredImage.node.sourceUrl
                        ? item.featuredImage.node.sourceUrl
                        : null
                    }
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default Slide;
