import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import { Autoplay, Pagination, Navigation } from "swiper/modules";
import SingleSlide from "./SingleSlide";
import { BASE_URL } from "../../../utils";

function Slide() {
  const [customerData, setCustomerData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{ 
  homePageSections(where: {termTaxonomyId: "8"}) {
    nodes {
      homePages(where: {orderby: {field: DATE, order: ASC}}) {
        nodes {
          title
          content
          featuredImage {
            node {
              sourceUrl
            }
          }
          homePagesSlider {
            pageUrl
          }
        }
      }
    }
  }
}
`,
          }),
        });
        const responseData = await response.json();
        // console.log(
        //   responseData.data.homePageSections.nodes[0].homePages.nodes[0]
        // );
        setCustomerData(
          responseData.data.homePageSections.nodes[0].homePages.nodes
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <Swiper
        spaceBetween={5}
        slidesPerView={5}
        centeredSlides={true}
        loop={true}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          480: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          740:{
            slidesPerView: 4,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1200: {
            slidesPerView: 6,
            spaceBetween: 30,
          },
          1400: {
            slidesPerView: 5,
            spaceBetween: 10,
          },
        }}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="swiper-wrapper pt-2 swiper-slider-2"
      >
        {customerData &&
          customerData.map((item, id) => {
            return (
              <SwiperSlide key={id}>
                <SingleSlide
                  src={item.featuredImage.node.sourceUrl}
                  alt={item.title}
                />
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
}

export default Slide;
