import React from "react";
import HeaderCard from "../Cards/HeaderCard";

function CardsSection() {
  return (
    <section className="manpower-cards manP">
      <div className="row d-flex justify-content-between">
        <HeaderCard
          index="01"
          title="Onsite IT e-Governance Manpower Services"
          desc="With onsite e-Governance- we champion pro-active people-led good governance. In the dynamic realm of digital governance, our expert team ensures optimization across e-Governance protocols, frameworks, and technologies."
        />
        <HeaderCard
          index="02"
          title="IT Support Services"
          desc="Exceeding expectations beyond the limits with IT! We stay relevant and play a role of a key driver of excellence. Our IT support solutions stand out as the right fit to meet and exceed the expectations of the core business."
        />
      </div>
    </section>
  );
}

export default CardsSection;
