import React, { useEffect, useState } from "react";
import Slide from "../Slides/Slide";
import { BASE_URL } from "../../../utils";

function Section2() {
  const [heading, setHeading] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
iTDivSections(where: {termTaxonomyId: "21"}) {
    nodes {
      topdata: iTDivisionPages(where: {id: 327, orderby: {field: DATE, order: ASC}}) {
        nodes {
        content
          title
         featuredImage {
            node {
              sourceUrl
            }
          }
          allPageData {
            mainHeading
            subHeading
          }

        }
      }
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(
        //   responseData.data.iTDivSections.nodes[0].topdata.nodes[0].allPageData
        //     .mainHeading
        // );
        setHeading(
          responseData.data.iTDivSections.nodes[0].topdata.nodes[0].allPageData
            .mainHeading
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <section data-aos="zoom-in-up" data-aos-duration="1000">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {heading && (
              <h3 className="fs-4 fw-bold mt-4 mb-2 text-center">{heading}</h3>
            )}
          </div>
        </div>
        <Slide />
      </div>
    </section>
  );
}

export default Section2;
