import React from "react";
function SingleSlide({ src, alt }) {
  return (
    <div className="swiper-slide d-flex align-items-center">
      <img src={src} alt={alt} id="customer-img" />
    </div>
  );
}

export default SingleSlide;
