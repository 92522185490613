import React from "react";
import Footer from "../../components/Common/Footer/Footer";
import Header from "../../components/MeteringSolutions/Header/Header";
import Social from "../../components/Common/Social/Social";
import Chatbot from "../../components/Common/Chatbot/Chatbot";
import Section1 from "../../components/MeteringSolutions/Sections/Section1";
import Section2 from "../../components/MeteringSolutions/Sections/Section2";
import Section3 from "../../components/MeteringSolutions/Sections/Section3";

function MeteringSolutions() {
  return (
    <div style={{ fontFamily: '"Montserrat", sans-serif' }}>
      <Header />
      <Section1 />
      <Section2 />
      <Section3 />
      <Footer />
      <Social />
      <Chatbot />
    </div>
  );
}

export default MeteringSolutions;
