import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../../utils";

function SectionAddress() {
  const [leftData, setLeftData] = useState(null);
  const [rightData, setRightData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `
              {
                contactSections(where: { termTaxonomyId: "43" }) {
                  nodes {
                    leftdata: contactUsPages(where: { id: 596 }) {
                      nodes {
                        content
                        allPageData {
                          mainHeading
                          subHeading
                          pageLink
                        }
                      }
                    }
                    rightdata: contactUsPages(
                      where: { orderby: { field: DATE, order: ASC }, notIn: "596" }
                    ) {
                      nodes {
                        content
                        allPageData {
                          mainHeading
                          subHeading
                          pageLink
                        }
                      }
                    }
                  }
                }
              }
            `,
          }),
        });

        const responseData = await response.json();
        const { leftdata, rightdata } =
          responseData.data.contactSections.nodes[0];
        setLeftData(leftdata.nodes[0]);
        setRightData(rightdata.nodes[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="">
      <div className="row">
        {leftData && (
          <div className="col-lg-6">
            <h3 className="ourtool_slide_title mt-0 mb-3">
              {leftData.allPageData.mainHeading}
            </h3>
            <p dangerouslySetInnerHTML={{ __html: leftData.content }}></p>
          </div>
        )}

        {rightData && (
          <div className="col-lg-6">
            <h3 className="ourtool_slide_title mt-0 mb-3">
              {rightData.allPageData.mainHeading}
            </h3>
            <p dangerouslySetInnerHTML={{ __html: rightData.content }}></p>
          </div>
        )}
      </div>

      <ul
        className="row nav nav-pills mb-3 office"
        id="pills-tab"
        role="tablist"
      >
        <li className="nav-item col" role="presentation">
          <button
            className="nav-link"
            id="pills-home-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-home"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="false"
            tabIndex="-1"
          >
            Get Direction
          </button>
        </li>
        <li className="nav-item ps-2 col" role="presentation">
          <button
            className="nav-link active"
            id="pills-profile-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-profile"
            type="button"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="true"
          >
            Get Direction
          </button>
        </li>
      </ul>

      <div
        className="row tab-content aos-init aos-animate"
        id="pills-tabContent"
        data-aos="zoom-out"
        data-aos-duration="1000"
      >
        <div
          className="tab-pane fade col-md-12 p-0"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
          tabIndex={0}
        >
          {leftData && (
            <div className="map fix mx-3">
              <iframe
                title="corporate"
                src={leftData.allPageData.pageLink}
                width="100%"
                height="270"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          )}
        </div>

        <div
          className="tab-pane fade col-md-12 p-0 show active"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
          tabIndex={0}
        >
          {rightData && (
            <div className="map fix mx-3">
              <iframe
                title="operations"
                src={rightData.allPageData.pageLink}
                width="100%"
                height="270"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SectionAddress;
