import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../utils";

function Section1() {
  const [firstSecData, setFirstSecData] = useState([]);
  const [secondSecData, setSecondSecData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  iTConsultancyPages(where: {id:442}) {
    nodes {
    title
    content
         featuredImage {
            node {
              sourceUrl
            }
          }
    }
  }
}
`,
          }),
        });
        const responseData = await response.json();
        // console.log(responseData.data.iTConsultancyPages.nodes[0]);
        setFirstSecData(responseData.data.iTConsultancyPages.nodes[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  iTConsultancyPages(where: {id:445}) {
    nodes {
    title
    content
         featuredImage {
            node {
              sourceUrl
            }
          }
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(responseData.data.iTConsultancyPages.nodes[0]);
        setSecondSecData(responseData.data.iTConsultancyPages.nodes[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="tiles container" id="tileContainer">
      <div className="row m-0" data-aos="fade-up" data-aos-duration="1000">
        <div
          className="col-sm-12 col-md-8"
          data-aos="slide-right"
          data-aos-duration="1000"
        >
          {firstSecData && (
            <h2 data-aos="fade-up" data-aos-duration="1200">
              {firstSecData.title}
            </h2>
          )}
          <div dangerouslySetInnerHTML={{ __html: firstSecData.content }}></div>
        </div>
        <div
          className="col-sm-12 col-md-4 d-flex align-items-center justify-content-end"
          data-aos="slide-left"
          data-aos-duration="1000"
        >
          {firstSecData &&
            firstSecData.featuredImage &&
            firstSecData.featuredImage.node && (
              <img
                src={firstSecData.featuredImage.node.sourceUrl}
                className="img-fluid"
                alt=""
              />
            )}
        </div>
      </div>

      <div className="row m-0" data-aos="fade-up" data-aos-duration="1000">
        <div
          className="col-sm-12 col-md-4 "
          data-aos="slide-right"
          data-aos-duration="1000"
        >
          {secondSecData &&
            secondSecData.featuredImage &&
            secondSecData.featuredImage.node && (
              <img
                src={secondSecData.featuredImage.node.sourceUrl}
                className="img-fluid"
                alt=""
              />
            )}
        </div>
        <div
          className="col-sm-12 col-md-8"
          data-aos="slide-left"
          data-aos-duration="1000"
        >
          {secondSecData && (
            <h2 data-aos="fade-up" data-aos-duration="1200">
              Key service lines
            </h2>
          )}
          <div
            dangerouslySetInnerHTML={{ __html: secondSecData.content }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default Section1;
