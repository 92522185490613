import React, { useEffect, useState } from "react";
import ServiceCard from "../Cards/ServiceCard";
import { BASE_URL } from "../../../utils";

function Section3() {
  const [topData, setTopData] = useState([]);
  const [bottomData, setBottomData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  meteringPageSections(where: {termTaxonomyId: "41"}) {
    nodes {
      topdata: meteringSolutionsPages(where: {id: 553, orderby: {field: DATE, order: ASC}}) {
        nodes {
         title
        featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
       bottomdata: meteringSolutionsPages( where: {orderby: {field: DATE, order: ASC}, notIn: "553"} ) {
       nodes {
         title
         content
        }
      }
      }
      }
    }`,
          }),
        });
        const responseData = await response.json();
        // console.log(
        //   responseData.data.meteringPageSections.nodes[0].bottomdata.nodes[0]
        // );
        setTopData(
          responseData.data.meteringPageSections.nodes[0].topdata.nodes[0]
        );
        setBottomData(
          responseData.data.meteringPageSections.nodes[0].bottomdata.nodes
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div
      className="container p-0 pt-5"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div className="metering-section position-relative d-sm-flex align-items-center w-md-100 h-100">
        <div className="metering-left d-sm-block p-0">
          <div className="row justify-content-center h-100 mx-2">
            {bottomData &&
              bottomData.map((item, index) => (
                <ServiceCard
                  key={index}
                  title={item.title}
                  desc={item.content}
                />
              ))}
          </div>
        </div>
        {topData && topData.featuredImage && topData.featuredImage.node && (
          <>
            <div className="metering-right d-none d-sm-none d-lg-flex h-100">
              <img
                src={topData.featuredImage.node.sourceUrl}
                alt=""
                className="img-fluid"
              />
            </div>
            <h1 className="title-overlay">{topData.title}</h1>
          </>
        )}
      </div>
    </div>
  );
}

export default Section3;
