import React, { useState, useEffect } from "react";
import Navbar from "../../Common/Navbar/Navbar";
import { BASE_URL } from "../../../utils";

function Header() {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  headersPages(where: {id:581}) {
    nodes {
      content
     featuredImage {
            node {
              sourceUrl
            }
          }
           allPageData {
            mainHeading
            subHeading
          }
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(responseData.data.headersPages.nodes[0]);
        setData(responseData.data.headersPages.nodes[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    // data &&
    // data.featuredImage && (
    // data.featuredImage.node &&
    // data.allPageData && (
    <header className="position-relative metering-header">
      <div className="container-fluid p-0">
        <div className="row py-2 banner">
          <Navbar />
          <div className="conatiner about-banner ">
            <div className="row align-items-center ">
              <div className=" col-12 col-md-12 text-center align-items-center d-flex justify-content-center bannerLeft">
                <div className="bannerText bannerLeft d-flex flex-column align-items-center">
                  <h1>
                    {data && data.allPageData
                      ? data.allPageData.mainHeading
                      : ""}
                  </h1>
                  <h3>
                    {data && data.allPageData
                      ? data.allPageData.subHeading
                      : ""}
                  </h3>
                  <div dangerouslySetInnerHTML={{ __html: data.content }}></div>

                  {/* <a
                      className="smButton2 justify-content-center fs-5"
                      href="https://portal.smartechenergy.in/client/login"
                      target="_blank"
                    >
                      Portal
                      <div className="icon">
                        <svg
                          height="24"
                          width="24"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path
                            d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </div>
                    </a> */}
                  <a
                    className="smButton2 justify-content-center fs-6"
                    href="/contact"
                    target="_blank"
                  >
                    Contact Us
                    <div className="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <a href="#resp-sec" className="scrollButton">
              <span></span>
              <span></span>
              <span></span>Scroll
            </a>
          </div>
        </div>
      </div>
    </header>
  );
  // );
}

export default Header;
