import React from "react";

function SkillCard({ imgUrl, title }) {
  return (
    <div className="cd-timeline__block">
      <div
        className="cd-timeline__img cd-timeline__img--movie"
        data-aos="zoom-out"
        data-aos-duration="1000"
      >
        <img src={imgUrl} alt={title} />
      </div>

      <div
        className="cd-timeline__content text-component"
        data-aos="flip-up"
        data-aos-duration="1000"
      >
        <p className="fw-semibold">{title}</p>
      </div>
    </div>
  );
}

export default SkillCard;
