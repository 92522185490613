import React from "react";
import Footer from "../../components/Common/Footer/Footer";
import Chatbot from "../../components/Common/Chatbot/Chatbot";
import Social from "../../components/Common/Social/Social";
import Support from "../../components/Common/Support/Support";
import Calculator from "../../components/Solar/Calculator/Calculator";
import Projects from "../../components/Solar/Projects/Projects";
import WhyUs from "../../components/Solar/WhyUs/WhyUs";
import Services from "../../components/Solar/Services/Services";
import Section1 from "../../components/Solar/Section1/Section1";
import Header from "../../components/Solar/Header/Header";

function Solar() {
  return (
    <div style={{ fontFamily: '"Montserrat", sans-serif' }}>
      <Header />
      <div>
        <Section1 />
        <Services />
        <WhyUs />
        <Projects />
        <Calculator />
        <Support mail="solar@smartechadvisory.com" />
        <Footer />
        <Social />
        <Chatbot />
      </div>
    </div>
  );
}

export default Solar;
