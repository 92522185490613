import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../../utils";

function Section1() {
  const [sectionData, setSectionData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  iTManpowerPages(where: {id:472}) {
    nodes {
    title
    content
     featuredImage {
            node {
              sourceUrl
            }
          }
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(responseData.data.iTManpowerPages.nodes[0]);
        setSectionData(responseData.data.iTManpowerPages.nodes[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="card-below-text manP">
      <div className="row align-items-center ">
        <div className="col-lg-12 text-center">
          {sectionData && (
            <h3
              className="fw-bold fs-2 mt-5 mb-4"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              {sectionData.title}
            </h3>
          )}
          {/* <h3
            className="fw-bold fs-2 mt-3 mb-4"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Prepare. Perform. Progress.
          </h3> */}
        </div>
        <div
          className="col-12 col-md-4 card-border-manpower"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          {sectionData &&
            sectionData.featuredImage &&
            sectionData.featuredImage.node && (
              <img
                src={sectionData.featuredImage.node.sourceUrl}
                alt=""
                className="img-fluid rounded-4"
              />
            )}
        </div>
        <div
          className="col-12 col-md-8 mt-4 mt-md-0"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <div dangerouslySetInnerHTML={{ __html: sectionData.content }}></div>
          {/* <p className=" font-16 ">
            Our IT manpower is the backbone behind the success of the new
            business world. Crafting a winning workforce strategy and
            modernising to deliver- we manage 360 degree to ensure you reap
            expected returns
          </p> */}
        </div>
      </div>
    </div>
  );
}

export default Section1;
