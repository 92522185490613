import React from "react";
import Footer from "../../components/Common/Footer/Footer";
import Chatbot from "../../components/Common/Chatbot/Chatbot";
import Social from "../../components/Common/Social/Social";
import Header from "../../components/SerIT/Header/Header";
import Section1 from "../../components/SerIT/Section1/Section1";
import Section2 from "../../components/SerIT/Section2/Section2";

function SerIT() {
  return (
    <div
      className="container-fluid p-0"
      style={{ fontFamily: '"Montserrat", sans-serif' }}
    >
      <Header />
      <div style={{ backgroundColor: "#eee" }}>
        <Section1 />
      </div>
      <Section2 />
      <Footer />
      <Social />
      <Chatbot />
    </div>
  );
}

export default SerIT;
