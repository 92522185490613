import React from "react";
import Slide from "./Slide";
function Section3() {
  return (
    <div
      className="container"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div className="container-width overflow-hidden ms-0">
        <Slide />
      </div>
    </div>
  );
}

export default Section3;
