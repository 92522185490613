import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../../utils";

function Section3() {
  const [imgUrl, setImgUrl] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
iTDivisionPages(where: {id:353}) {
    nodes {
          featuredImage {
            node {
              sourceUrl
            }
          }
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(
        //   responseData.data.iTDivisionPages.nodes[0].featuredImage.node
        //     .sourceUrl
        // );
        setImgUrl(
          responseData.data.iTDivisionPages.nodes[0].featuredImage.node
            .sourceUrl
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <section
      className="tech-banner-section container-fluid p-0"
      data-aos="zoom-out-up"
      data-aos-duration="1000"
    >
      {imgUrl && <img src={imgUrl} className="tech-banner-img" alt="" />}
      {/* <img src={images.techSecBanner} className="tech-banner-img" alt="" /> */}
    </section>
  );
}

export default Section3;
