// for dev env
// "proxy": "https://smartech.kreatetechnologies.com/smartech-api",
// const BASE_URL = "/graphql";
// const BASE_REST_URL = "/wp-json/custom/v1/menu";
// const BASE_FORM_URL = "/wp-json/custom/v1/send-email";
// const BASE_CHATBOT_URL = "https://aichatbot.redfxenergy.com:5000/chat";

const BASE_URL = process.env.REACT_APP_API_URL;
const BASE_REST_URL = process.env.REACT_APP_REST_API_URL;
const BASE_FORM_URL = process.env.REACT_APP_FORM_URL;
const BASE_CHATBOT_URL = process.env.REACT_APP_CHATBOT_URL;

function removeTag(text) {
  return text ? text.slice(3, -6) : text;
}

let debug = true;

function log(TAG, message) {
  if (debug) {
    console.log(TAG, `${message}`);
  }
}

export {
  removeTag,
  BASE_URL,
  BASE_REST_URL,
  BASE_FORM_URL,
  BASE_CHATBOT_URL,
  log,
};
