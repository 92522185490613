import React from "react";
import Support from "../../components/Common/Support/Support";
import Services from "../../components/Consultancy/Services/Services";
import Footer from "../../components/Common/Footer/Footer";
import Social from "../../components/Common/Social/Social";
import Section2 from "../../components/Consultancy/Section2/Section2";
import Header from "../../components/Consultancy/Header/Header";
import Chatbot from "../../components/Common/Chatbot/Chatbot";

function Consultancy() {
  return (
    <div style={{ fontFamily: '"Montserrat", sans-serif' }}>
      {/* <Section1 /> */}
      <Header />
      <Section2 />
      <Services />
      <Support mail="sales@smartechadvisory.com" id="contactUsForm" />
      <Footer />
      <Social />
      <Chatbot />
    </div>
  );
}

export default Consultancy;
