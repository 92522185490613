import React from "react";

function SingleSlide({ imgUrl, title, link }) {
  return (
    <div>
      <div className="card-image-section">
        <div className="glass shadow">
          <a
            href={link}
            className="d-flex flex-column justify-content-center align-items-center my-5"
            style={{ textDecoration: "none" }}
          >
            <span>
              <img src={imgUrl} alt="" className="img-fluid slider-energy" />
            </span>
            <p className="paragraph">{title}</p>
          </a>
        </div>
        <div className="transform shadow"></div>
      </div>
    </div>
  );
}

export default SingleSlide;
