import React from "react";

import Footer from "../../components/Common/Footer/Footer";
import Social from "../../components/Common/Social/Social";
import Chatbot from "../../components/Common/Chatbot/Chatbot";

import Header from "../../components/Media/Header/Header";
import UnderDevelopement from "../../components/Common/UnderDeveopment/UnderDevelopement";

function Media() {
  return (
    <div style={{ fontFamily: '"Montserrat", sans-serif' }}>
      <Header />
      {/* <Tabs /> */}
      <UnderDevelopement />
      <Footer />
      <Social />
      <Chatbot />
    </div>
  );
}

export default Media;
