import React from "react";
import { Card } from "react-bootstrap";

const FeatureCard = ({ title, desc, bgImgUrl }) => {
  return (
    <Card
      className=" card1 a-card shadow-lg border-0 mb-3"
      style={{ backgroundImage: `url(${bgImgUrl})` }}
    >
      {/* <Card.Body> */}
      <h6 className="a-card-title text-light">{title}</h6>
      {/* <p className="a-card-text wrap text-light mt-3">{desc}</p> */}
      <Card.Text
        className="a-card-text wrap text-light mt-3"
        dangerouslySetInnerHTML={{ __html: desc }}
      ></Card.Text>
      {/* </Card.Body> */}
    </Card>
  );
};

export default FeatureCard;
