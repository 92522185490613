import React from "react";

function ServiceCard({ title, desc }) {
  return (
    <div className="col-lg-3 col-md-6 col-12 mertering-col">
      <div className="card a-card shadow-lg border-0 h-100 metering-card">
        <h5 className="a-card-title">{title}</h5>
        <div dangerouslySetInnerHTML={{ __html: desc }}></div>
      </div>
    </div>
  );
}

export default ServiceCard;
