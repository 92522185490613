import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import { images } from "../../../res/images";
import solarCommission from "../../../res/assets/solarCommission.svg";
import solarMaintaine from "../../../res/assets/solarMaintaine.svg";
import solarServiceInstall from "../../../res/assets/solarServiceInstall.svg";

import ServiceCard from "../Cards/ServiceCard";
import { Autoplay } from "swiper/modules";
import { BASE_URL } from "../../../utils";

export default function Slide() {
  const [slideData, setSlideData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  solarPageSections(where: {termTaxonomyId: "18"}) {
    nodes {
      bottomdata: solarPages( where: {orderby: {field: DATE, order: ASC}, notIn: "310"} ) {
        nodes {
          content
          featuredImage {
            node {
              sourceUrl
            }
          }
          allPageData {
            mainHeading
          }
          infraSolar {
            smallImage {
              node {
                mediaItemUrl
              }
            }
            secondEditor
          }
        }
      }
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(
        //   responseData.data.solarPageSections.nodes[0].bottomdata.nodes[0]
        // );
        setSlideData(
          responseData.data.solarPageSections.nodes[0].bottomdata.nodes
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const serviceData = [
    {
      id: 1,
      title: "Solar Installation and Commissioning",
      desc: "Solar installation and commissioning standout as a crucial stage for implementing a solar energy system. This entails the physical installation of solar panels, electrical components, and associated equipment, accompanied by a series of tests and inspections to ensure proper functioning and compliance with safety standards.",
      subTitle: "Solar installation and commissioning that we cater include:",
      imgUrl: images.solarInstallation,
      iconUrl: solarServiceInstall,
      points: [
        "Site Assessment",
        "Design and Engineering",
        "Procurement and Logistics",
        "Installation",
        "Electrical Connections and Testing",
        "Commissioning and System Testing",
        "Safety Inspections and Permits",
        "Handover and Documentation",
      ],
      pageLink: "service-one",
    },
    {
      id: 2,
      title: "Solar Operation and Maintenance Services",
      desc: "Solar operation and maintenance services are paramount to set the seal on long-term performance, reliability, and efficiency of solar energy systems. These services take in a range of activities focussed at identifying and addressing issues, conducting preventive and corrective maintenance and optimising the overall operation of the solar installation.",
      subTitle:
        "Solar operation and maintenance services that we cater include:",
      imgUrl: images.solarMaintain,
      iconUrl: solarMaintaine,

      points: [
        "System Inspection",
        "Cleaning and Debris Removal",
        "24x7 Performance Monitoring",
        "Inverter Maintenance",
        "Electrical System Maintenance",
        "System Repairs",
        "Performance Optimization",
        "Documentation and Reporting",
      ],
      pageLink: "service-two",
    },
    {
      id: 3,
      title: "Consultancy for Solar Project Management",
      desc: "Consultancy for solar plants call for expert advice and assistance to individuals, businesses, or organisations with a keen eye towards implementing solar energy systems. This type of consultancy covers various aspects of solar plant development, including feasibility studies, design, installation, and maintenance.",
      subTitle: "Consultancy for solar plants that we cater include:",
      imgUrl: images.solarConsultancy,
      iconUrl: solarCommission,

      points: [
        "Feasibility Studies",
        "Load List Analysis",
        "Design and Engineering",
        "Procurement and Vendor Selection",
        "Permitting and Regulatory Compliance",
        "Financial Analysis and Funding",
        "Project Management",
      ],
      pageLink: "service-three",
    },
  ];
  // console.log("Slide data............", slideData);
  return (
    <div className="swiper" id="mySwiper1">
      <Swiper
        // centeredSlides={true}
        // spaceBetween={10}
        loop={true}
        slidesPerView={3}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        breakpoints={{
          40: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        modules={[Autoplay]}
        className="mySwiper"
      >
        {/* {slideData &&
          slideData.length > 0 &&
          slideData.map((item, index) => (
            <SwiperSlide className="swiper-slide key={index}">
              <ServiceCard
                key={index}
                id={index}
                title={item.allPageData ? item.allPageData.mainHeading : ""}
                imgUrl={
                  item.featuredImage && item.featuredImage.node
                    ? item.featuredImage.node.sourceUrl
                    : ""
                }
                iconUrl={
                  item.infraSolar &&
                  item.infraSolar.smallImage &&
                  item.infraSolar.smallImage.node
                    ? item.infraSolar.smallImage.node.mediaItemUrl
                    : ""
                }
                desc={item.content ? item.conent : ""}
                points={item.points}
                subTitle={item.infraSolar ? item.infraSolar.secondEditor : ""}
              />
            </SwiperSlide>
          ))} */}
        {serviceData.map((item, index) => (
          <SwiperSlide key={index} className="swiper-slide">
            <ServiceCard
              id={index}
              title={item.title}
              desc={item.desc}
              points={item.points}
              subTitle={item.subTitle}
              link={item.pageLink}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
