import React, { useEffect, useState } from "react";
import LeftCard from "../Card/LeftCard";
import RightCard from "../Card/RightCard";
import { BASE_URL } from "../../../utils";

function Section1() {
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
iTServiceSections(where: {termTaxonomyId: "20"}) {
    nodes {
      topdata: iTServicePages(where: {orderby: {field: DATE, order: ASC}}) {
        nodes {
          allPageData {
            mainHeading
            subHeading
            pageLink
          }
         featuredImage {
            node {
              sourceUrl
            }
          }
        content
        }
      }
    
    }
  }
}
`,
          }),
        });
        const responseData = await response.json();
        // console.log(
        //   responseData.data.iTServiceSections.nodes[0].topdata.nodes[0].content
        // );
        setServices(responseData.data.iTServiceSections.nodes[0].topdata.nodes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <section className="techStack" id="techStack">
      <div className="container p-3 d-flex flex-column justify-content-center align-items-center">
        {services &&
          (() => {
            const rows = [];
            for (let id = 0; id < services.length; id += 2) {
              const firstItem = services[id];
              const secondItem = services[id + 1];
              const row = (
                <div className="row m-0 d-flex justify-content-center" key={id}>
                  <LeftCard
                    key={firstItem.id}
                    service={firstItem}
                    id={firstItem.id}
                  />
                  {secondItem && (
                    <RightCard
                      key={secondItem.id}
                      service={secondItem}
                      id={secondItem.id}
                    />
                  )}
                </div>
              );
              rows.push(row);
            }
            return rows;
          })()}
        {/* <div className="row m-0 d-flex justify-content-center">
          <LeftCard />
          <RigthCard />
        </div>
        <div className="row m-0 d-flex justify-content-center">
          <LeftCard />
          <RigthCard />
        </div>
        <div className="row m-0 d-flex justify-content-center">
          <LeftCard />
          <RigthCard />
        </div>
        <div className="row m-0 d-flex justify-content-center">
          <LeftCard />
          <RigthCard />
        </div>
        <div className="row m-0 d-flex justify-content-center">
          <LeftCard />
          <RigthCard />
        </div> */}
      </div>
    </section>
  );
}

export default Section1;
