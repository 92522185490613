import React, { useState, useEffect } from "react";
import renew from "../../../res/assets/renew.svg";
import { BASE_URL } from "../../../utils";
function Section1() {
  const [leftData, setLeftData] = useState([]);
  const [midData, setMidData] = useState([]);
  const [rightData, setRightData] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
    solarPageSections(where: {termTaxonomyId: "17"}) {    
    nodes{
    leftdata: solarPages(where: {id: 301, orderby: {field: DATE, order: ASC}}) {
        nodes {
          content
          allPageData {
            mainHeading
            subHeading
          }

        }
      }
    middata: solarPages(where: {id: 305, orderby: {field: DATE, order: ASC}}) {
        nodes {
          
          allPageData {
            mainHeading
            subHeading

          }
        }
      }  
    rightdata: solarPages(where: {id: 308, orderby: {field: DATE, order: ASC}}) {
        nodes {
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }   
     }
  } 
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(
        //   responseData.data.solarPageSections.nodes[0].rightdata.nodes[0]
        //     .featuredImage.node.sourceUrl
        // );
        setLeftData(
          responseData.data.solarPageSections.nodes[0].leftdata.nodes[0]
        );
        setMidData(
          responseData.data.solarPageSections.nodes[0].middata.nodes[0]
            .allPageData
        );
        setRightData(
          responseData.data.solarPageSections.nodes[0].rightdata.nodes[0]
            .featuredImage.node.sourceUrl
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="wrapper1">
      <div className="container-fluid p-0">
        <div className="who dp solar-sec">
          <div className="row justify-content-between">
            <div
              className="col-md-12 col-lg-6 d-flex align-items-center justify-content-start gap-2"
              data-aos="slide-right"
              data-aos-duration="800"
            >
              {leftData && leftData.allPageData && (
                <div className="text-cont">
                  <p className="main-heading m-0" data-aos="fade-up">
                    {leftData.allPageData.mainHeading}
                  </p>
                  <p
                    className="fw-bolder fs-2 head-line mb-3"
                    data-aos="fade-up"
                  >
                    {leftData.allPageData.subHeading}
                  </p>
                  <div
                    dangerouslySetInnerHTML={{ __html: leftData.content }}
                  ></div>
                </div>
              )}
              {/* <div className="text-cont">
                <p className="main-heading m-0" data-aos="fade-up">
                  WHO WE ARE
                </p>
                <p className="fw-bolder fs-2 head-line mb-3" data-aos="fade-up">
                  Energy for the World
                </p>
                <p data-aos="fade-up">
                  At SmarTech, we are one of the fastest growing solar solution
                  providers, utilising cutting-edge technologies to address the
                  world's renewable energy needs.
                </p>
                <p data-aos="fade-up">
                  Highly motivated by one of our mission's to build a greener
                  and more sustainable future. We believe that by harnessing the
                  power of renewable resources, we can make a remarkable impact
                  on mitigating climate change and eliminating carbon footprint.
                </p>
                <p data-aos="fade-up">
                  We work together with our clients to understand their energy
                  needs and provide customised solutions that come to be highly
                  efficient and cost-effective. Our well-designed solar systems
                  can significantly reduce your electricity bills.
                </p>

                <p data-aos="fade-up">
                  With many years of expertise in project development and
                  implementation. From site selection and feasibility studies to
                  engineering and construction, we manage every facet of the
                  project prior to successful completion.
                </p>
                <p data-aos="fade-up">
                  We have installed a myriad of solar rooftop and ground mounted
                  projects, and have established a global presence with offices
                  across India and Worldwide.
                </p>
              </div> */}
            </div>
            <div
              className="col-md-12 col-lg-6 d-flex justify-content-end align-items-lg-center"
              data-aos="slide-left"
              data-aos-duration="800"
            >
              <div className="energy-cont d-flex justify-content-end h-100">
                <div
                  className="img-cont-who rounded"
                  style={{ backgroundImage: { rightData } }}
                ></div>
                <div className="energy-text-cont p-2 p-md-4 text-center rounded d-flex justify-content-center align-items-center flex-column">
                  <p className="mb-2">
                    <img src={renew} alt="" />
                  </p>
                  {midData && (
                    <>
                      <p
                        className="text-uppercase backClr f2"
                        data-aos="fade-up"
                      >
                        {midData.mainHeading}
                      </p>
                      <p
                        className="fw-bolder f1 text-center head-line pt-1"
                        data-aos="fade-up"
                      >
                        {midData.subHeading}
                      </p>
                    </>
                  )}
                  {/* <p className="text-uppercase backClr f2" data-aos="fade-up">
                    renewable energy
                  </p>
                  <p
                    className="fw-bolder f1 text-center head-line pt-1"
                    data-aos="fade-up"
                  >
                    Everything Gets Done With the Sun.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section1;
