import React, { useState } from "react";
import ResultModal from "./ResultModal";

function Calculator() {
  const [showModal, setShowModal] = useState(false);
  const [isRoofTop, setIsRoofTop] = useState(true);
  const [modalData, setModalData] = useState({});
  const [customer, setCustomer] = useState("");
  const [tariff, setTariff] = useState(null);
  const [area, setArea] = useState(null);
  const [address, setAddress] = useState("");
  const handleCalculate = () => {
    const calculatedData = {
      solarCapacity: (isRoofTop ? area / 75.35 : area / 100).toFixed(2),
      annualGeneration: ((area / 100) * 4 * 30 * 12).toLocaleString("en-IN"),
      annualSaving: ((area / 100) * 4 * 30 * 12 * tariff).toLocaleString(
        "en-IN"
      ),
      expectedCost: (area / 100 < 7
        ? (area / 100) * 54000
        : (area / 100) * 45000
      ).toLocaleString("en-IN"),
      returnOnInvestment: customer,
      address: address,
    };

    setModalData(calculatedData);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const calculateHandler = () => {
    if (!customer || !area || !tariff || !address) {
      alert("Fill all fields of the calculator");
      return;
    }
    handleCalculate();
    // console.log(modalData);
  };

  return (
    <div
      className="green-energy m-0 solar-sec"
      id="solarCalculator"
      data-aos="fade-up"
    >
      <div className="container mt-3 mt-md-5 mb-3 mb-md-5">
        <div className="row">
          <div className="col-md-12 col-lg-12" id="Calculator">
            <p
              className="main-heading m-0 mb-2 text-center text-light fs-"
              data-aos="fade-up"
            >
              Multiply Savings with Solar
            </p>
            <h3
              className="calculator-title fs-2 mb-3 mb-md-5 text-white"
              data-aos="fade-up"
            >
              Solar Calculator
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-5 pl-0" data-aos="fade-up">
            <label htmlFor="customer">1. Choose</label>
            <div className="btn-container">
              <label className="switch btn-color-mode-switch mb-4">
                <input
                  type="checkbox"
                  name="color_mode"
                  id="color_mode"
                  value="1"
                />
                <label
                  htmlFor="color_mode"
                  data-on="Solar panel capacity you want to install"
                  data-off="Roof Top Space"
                  className="btn-color-mode-switch-inner"
                  onClick={() => setIsRoofTop(!isRoofTop)}
                ></label>
              </label>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 col-sm-12" data-aos="fade-up">
            <div className="form-group mb-4">
              <label htmlFor="customer">2. Type of Customer</label>
              <select
                className="form-control"
                id="customer"
                value={customer}
                onChange={(e) => setCustomer(e.target.value)}
              >
                <option value="0">--- Select Customer ---</option>
                <option value="7">Residential</option>
                <option value="4">Commercial</option>
                <option value="4">Industrial</option>
                <option value="4">Institutional</option>
              </select>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 col-sm-12" data-aos="fade-up">
            <div className="form-group mb-4">
              <label htmlFor="electricity">
                3. Electricity tariff(Rs/Unit)
              </label>
              <input
                type="text"
                className="form-control"
                id="electricity"
                placeholder="Rs/Unit"
                value={tariff}
                onChange={(e) => setTariff(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-md-12 col-lg-5 col-sm-12 mb-2 mb-lg-0"
            data-aos="fade-up"
          >
            <div id="solor-install">
              <div className="form-group d-flex flex-column flex-lg-row align-items-lg-center">
                <input
                  type="text"
                  className="form-control"
                  id="solor"
                  placeholder=""
                />
                <span className="calculator-kwunit solor-install mt-2 mt-lg-0">
                  kW
                </span>
              </div>
            </div>
            <div className="form-group roof-top">
              <div className="d-flex flex-column flex-lg-row align-items-lg-center">
                <input
                  type="text"
                  className="form-control order-2 order-lg-1"
                  id="roof"
                  placeholder="Sq.Feet"
                  value={area}
                  onChange={(e) => setArea(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 mt-2 mt-lg-0" data-aos="fade-up">
            <div className="form-group row align-items-center">
              <label className="col-lg-2 mb-0" htmlFor="electricity">
                Address <span className="text-danger">*</span>
              </label>
              <div className="position-relative col-lg-10">
                <input
                  type="text"
                  className="form-control"
                  id="addressInfo"
                  required
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row" data-aos="fade-up">
          <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center mt-4">
            <button
              className="smButton2"
              id="submit"
              type="button"
              onClick={calculateHandler}
            >
              Calculate
              <div className="icon">
                <svg
                  height="24"
                  width="24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
            </button>
          </div>
        </div>
      </div>
      {showModal && (
        <ResultModal
          showModal={showModal}
          closeModal={handleCloseModal}
          modalData={modalData}
        />
      )}
    </div>
  );
}

export default Calculator;
