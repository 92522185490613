import React from "react";
function WhyCard({ title, imgUrl, iconUrl, desc }) {
  return (
    <div
      className="col-sm-12 col-md-4 col-lg-3 p-0 solarCard p-3"
      data-aos="fade-up"
      data-aos-duration="800"
    >
      <div className="solarCardWrapper position-relative ">
        <img src={imgUrl} alt="" className="solarCardBg" />
        <div className="cardLayer">
          <div className="layerWrapper">
            <img src={iconUrl} className="cardIcon" alt="" />
            <h5 className="cardTitle">{title}</h5>
            <div dangerouslySetInnerHTML={{ __html: desc }}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyCard;
