import React, { useState, useEffect } from "react";
import SkillCard from "../Card/SkillCard";
import { BASE_URL } from "../../../utils";

function Section2() {
  const [topData, setTopData] = useState("");
  const [bottomData, setBottomData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
consultancySections(where: {termTaxonomyId: "35"}) {
    nodes {
      topdata: iTConsultancyPages(where: {id: 448, orderby: {field: DATE, order: ASC}}) {
        nodes {
          title
        }
      }
      bottomdata: iTConsultancyPages(
        where: {orderby: {field: DATE, order: ASC}, notIn: "448"}
      ) {
       nodes {
             title
         featuredImage {
            node {
              sourceUrl
            }
          }
        }
    }
  }
}
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(
        //   responseData.data.consultancySections.nodes[0].bottomdata.nodes[0]
        // );
        setTopData(
          responseData.data.consultancySections.nodes[0].topdata.nodes[0].title
        );
        setBottomData(
          responseData.data.consultancySections.nodes[0].bottomdata.nodes
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <section
      className="cd-timeline js-cd-timeline"
      data-aos="zoom-out"
      data-aos-duration="1000"
    >
      {topData && <h2 className="text-center">{topData}</h2>}
      <div className="container max-width-lg cd-timeline__container">
        {/* {bottomData &&
          bottomData.map((item, id) => {
            <SkillCard
              key={id}
              title={item.itle ? item.title : ""}
              // imgUrl={item.featuredImg.node.sourceUrl}
              imgUrl={item.featuredImg.node.sourceUrl}
            />;
          })} */}
        {bottomData &&
          bottomData.map((item, id) => (
            <SkillCard
              key={id}
              title={item.title ? item.title : ""}
              imgUrl={
                item.featuredImage && item.featuredImage.node
                  ? item.featuredImage.node.sourceUrl
                  : ""
              }
            />
          ))}
      </div>
    </section>
  );
}

export default Section2;
