import React, { useState } from "react";
import { BASE_FORM_URL } from "../../../utils";

function Appointment({ formType }) {
  const [formData, setFormData] = useState({
    name: "",
    companyName: "",
    contactNumber: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.companyName) newErrors.companyName = "Company is required";
    if (!formData.contactNumber) newErrors.contactNumber = "Phone is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.message) newErrors.message = "Message is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const data = new FormData();
      data.append("name", formData.name);
      data.append("company", formData.companyName);
      data.append("phone", formData.contactNumber);
      data.append("frommail", formData.email);
      data.append("message", formData.message);
      data.append("formType", formType);

      try {
        const response = await fetch(BASE_FORM_URL, {
          method: "POST",
          body: data,
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();
        // console.log("Success:", result);
      } catch (error) {
        console.error("Error:", error);
      }
      // console.log("Form data:", Object.fromEntries(data));
      setFormData({
        name: "",
        companyName: "",
        contactNumber: "",
        email: "",
        message: "",
      });
      // alert("Form submitted");
    }
  };

  return (
    <div
      className="col-12 col-md-6 my-4"
      data-aos="slide-left"
      data-aos-duration="800"
    >
      <div
        className="card p-4 p-lg-0"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div className="row justify-content-center">
          <div className="col-lg-9 py-4">
            <p className="ps-0 main-heading m-0" data-aos="fade-up">
              Contact Us
            </p>
            <p
              className="ps-0 mb-3 fw-bold m-0 fs-3"
              style={{ textAlign: "left" }}
              data-aos="fade-up"
            >
              Let's start your project
            </p>
            <form data-aos="fade-up" onSubmit={handleSubmit}>
              <div className="row justify-content-start justify-content-md-start justify-content-md-center g-3">
                <div className="col-12 col-md-6">
                  <label htmlFor="your-name" className="form-label fw-bold m-0">
                    Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="your-name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    placeholder="Name"
                    data-aos="fade-up"
                  />
                  {errors.name && (
                    <div className="text-danger">{errors.name}</div>
                  )}
                </div>
                <div className="col-12 col-md-6">
                  <label
                    htmlFor="company-name"
                    className="form-label fw-bold m-0"
                  >
                    Company<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="company-name"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                    required
                    placeholder="Company"
                    data-aos="fade-up"
                  />
                  {errors.companyName && (
                    <div className="text-danger">{errors.companyName}</div>
                  )}
                </div>
                <div className="col-12 col-md-6">
                  <label
                    htmlFor="contact-number"
                    className="form-label fw-bold m-0"
                  >
                    Phone<span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="contact-number"
                    name="contactNumber"
                    value={formData.contactNumber}
                    onChange={handleChange}
                    required
                    placeholder="Phone"
                    data-aos="fade-up"
                  />
                  {errors.contactNumber && (
                    <div className="text-danger">{errors.contactNumber}</div>
                  )}
                </div>
                <div className="col-12 col-md-6">
                  <label
                    htmlFor="your-email"
                    className="form-label fw-bold m-0"
                  >
                    Email<span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="your-email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    placeholder="Email"
                    data-aos="fade-up"
                  />
                  {errors.email && (
                    <div className="text-danger">{errors.email}</div>
                  )}
                </div>
                <div className="col-12 col-md-12">
                  <label
                    htmlFor="your-message"
                    className="form-label fw-bold m-0"
                  >
                    Message<span className="text-danger">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    id="your-message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    placeholder="Message"
                    rows="4"
                    data-aos="fade-up"
                  ></textarea>
                  {errors.message && (
                    <div className="text-danger">{errors.message}</div>
                  )}
                </div>
                <div className="col-12 col-md-12">
                  <button type="submit" data-aos="fade-up" className="smButton">
                    Send
                    <div className="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Appointment;
