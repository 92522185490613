import React from "react";
import gear from "../../../res/assets/gear.svg";
import { images } from "../../../res/images";
function HeaderCard({ index, title, desc }) {
  return (
    <div class="col-12 col-md-4">
      <div class="card overflow-hidden mt-4 mt-md-0 manpower-card">
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <img src={gear} alt="" />
            </div>
            <div class="col-6 icon-number-manPower">{index}</div>
          </div>
          <div class="row">
            <div class="col-12 col-md-11 col-lg-11">
              <p class="my-2 m-0 fw-bold fs-5">{title}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-11 col-lg-11">
              <p class="m-0">{desc}</p>
            </div>
            <div class="half-circle-cont">
              <div class="half-circle">
                <a href="">
                  <img src={images.rightArrow} class="img-fluid" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderCard;
