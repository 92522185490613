import React, { useEffect, useState } from "react";
import Navbar from "../../Common/Navbar/Navbar";

import { BASE_URL } from "../../../utils";
function Header() {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  headersPages(where: {id:570}) {
    nodes {
     featuredImage {
            node {
              sourceUrl
            }
          }
           allPageData {
            mainHeading
            subHeading
          }
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(
        //   responseData.data.headersPages.nodes[0].featuredImage.node.sourceUrl
        // );
        setData(responseData.data.headersPages.nodes[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    // data &&
    // data.featuredImage &&
    // data.allPageData && (
    <div
      className="container-fluid banner position-relative"
      style={{
        background:
          data && data.featuredImage
            ? `linear-gradient(290deg, #000000d1, #439a7dbc),url(${data.featuredImage.node.sourceUrl}) no-repeat center bottom`
            : "",
        height: "100vh",
        backgroundBlendMode: "multiply",
      }}
    >
      <Navbar />
      <div className="conatiner about-banner ">
        <div className="row align-items-center ">
          <div className=" col-12 col-md-12  text-center">
            <h1>{data.allPageData ? data.allPageData.mainHeading : ""}</h1>
            <p className="wrap p-0 text-center m-0 text-capitalize">
              {data.allPageData ? data.allPageData.subHeading : ""}
            </p>
            <div className="button-box d-flex justify-content-center ">
              <a href="#contactFormSection">
                <button className="smButton2 mt-3">
                  Contact Us
                  <div className="icon">
                    <svg
                      height="24"
                      width="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <a href="#advisory" className="scrollButton">
        <span></span>
        <span></span>
        <span></span>Scroll
      </a>
    </div>
  );
  // );
}

export default Header;
