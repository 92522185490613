import React from "react";

function Card({ heading, subHeading, description, pageLink }) {
  return (
    <div className="col-lg-4 mb-sm-3 consult-col-hover d-flex justify-content-center mb-lg-0 mb-4">
      <div className="card text-decoration-none consultz_card_section_card text-center">
        <div>
          <h3>{heading}</h3>
          <h5>{subHeading}</h5>
          <div dangerouslySetInnerHTML={{ __html: description }}></div>
        </div>
        <a href={pageLink}>
          <i className="ri-arrow-right-line"></i>
        </a>
      </div>
    </div>
  );
}

export default Card;
