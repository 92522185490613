import React, { useState, useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import ServiceCard from "../Cards/ServiceCard";
import { BASE_URL } from "../../../utils";
function ServiceSlide() {
  const [slideData, setSlideData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
iTDivSections(where: {termTaxonomyId: "19"}) {
    nodes {
      bottomdata: iTDivisionPages(
        where: {orderby: {field: DATE, order: ASC}, notIn: "332"}
      ) {
        nodes {
          featuredImage {
            node {
              sourceUrl
            }
          }
          allPageData {
            mainHeading
            subHeading
            pageLink
          }
          
        }
      }
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(responseData.data.iTDivSections.nodes[0].bottomdata.nodes);
        setSlideData(responseData.data.iTDivSections.nodes[0].bottomdata.nodes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="row text-center my-sm-2 my-md-3 my-lg-2  py-3">
      <div className="col-12">
        <div className="swiper mySwiper-proj" id="mySwiper-proj">
          <Swiper
            spaceBetween={5}
            centeredSlides={true}
            loop={true}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              480: {
                slidesPerView: 2,
                spaceBetween: 20,
              },

              768: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            navigation={true}
            modules={[Autoplay, Navigation]}
            className="swiper-wrapper"
          >
            <div className="swiper-wrapper">
              {slideData &&
                slideData.length > 0 &&
                slideData.map((item, id) => (
                  <SwiperSlide key={id}>
                    <ServiceCard
                      title={item.allPageData.mainHeading}
                      desc={item.allPageData.subHeading}
                      imgUrl={item.featuredImage.node.sourceUrl}
                    />
                  </SwiperSlide>
                ))}
              {/* <SwiperSlide>
                <ServiceCard
                  title="Data Security"
                  desc="Powerful Cloud Security Solutions"
                  imgUrl={serv1}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ServiceCard
                  title="SEC and Optimization"
                  desc="Project Management system and Software"
                  imgUrl={serv4}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ServiceCard
                  title="Analytics and Research"
                  desc="Integration Dynamics CRM with External Services"
                  imgUrl={serv11}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ServiceCard
                  title="IT Services"
                  desc="End-to-End SaaS Development and Support"
                  imgUrl={serv15}
                />
              </SwiperSlide> */}
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default ServiceSlide;
