import React from "react";

function ServiceCard({ imgUrl, title, desc }) {
  return (
    // <div className="swiper-wrapper">
    <div className="swiper-slide">
      <div className="project-section">
        <img src={imgUrl} alt="" className="img-fluid mb-4" />
        <h6 className="mb-2">{title}</h6>
        <p>{desc}</p>
      </div>
    </div>
    // </div>
  );
}

export default ServiceCard;
