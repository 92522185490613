import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../utils";

function Section3() {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  iTConsultancyPages(where: {id:467}) {
    nodes {
    title
    content
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(responseData.data.iTConsultancyPages.nodes[0]);
        setData(responseData.data.iTConsultancyPages.nodes[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="endNote" data-aos="fade-up" data-aos-duration="800">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 d-flex flex-column  align-items-center justify-content-center p-2">
            {data && (
              <>
                <h4>{data.title}</h4>
                <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section3;
