import React from "react";

function StatsCard({ count, count_title, content, lastClass }) {
  return (
    <div className={`item py-2 first ${lastClass ? "third px-3" : ""}`}>
      <h3 className="feedback-h3">
        <span className="count1">{count}</span>+
      </h3>
      <h5 className="mb-1 fw-bold">{count_title}</h5>
      <div dangerouslySetInnerHTML={{ __html: content }}></div>
      {/* <p className="mt-0 mb-2 text-start feedback-p">{content}</p> */}
    </div>
  );
}

export default StatsCard;
