import React, { useEffect, useState } from "react";
import Navbar from "../../Common/Navbar/Navbar";
import { BASE_URL } from "../../../utils";

function Header() {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  headersPages(where: {id:587}) {
    nodes {
      title
      content
      featuredImage {
            node {
              sourceUrl
            }
          }
           allPageData {
            mainHeading
            subHeading
            pageLink
            
          }
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(responseData.data.headersPages.nodes[0]);
        setData(responseData.data.headersPages.nodes[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    // data &&
    // data.featuredImage &&
    // data.featuredImage.node &&
    // data.allPageData && (
    <header
      className="banner position-relative"
      style={{
        background:
          data && data.featuredImage && data.featuredImage.node
            ? `linear-gradient(290deg, #000000d1, #439a7dbc), url(${data.featuredImage.node.sourceUrl})`
            : "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="header-wrapper">
        <Navbar />
        <div className="container-fluid inner-content-manpower">
          <div className="row px-5">
            <div className="col-12 col-md-9 manP text-light">
              <h1>{data ? data.title : ""}</h1>
              <h2 className="text-start">
                {data && data.allPageData ? data.allPageData.mainHeading : ""}
              </h2>
              <h3>{data ? data.subHeading : ""}</h3>
              <div
                dangerouslySetInnerHTML={{ __html: data ? data.content : "" }}
              ></div>
              <div className="row">
                <div className="col-12 col-md-6 d-flex align-items-center p-0">
                  <a
                    className="smButton2"
                    href={
                      data && data.allPageData ? data.allPageData.pageLink : ""
                    }
                  >
                    Contact Us
                    <div className="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a href="#card-manpower" className="scrollButton">
        <span></span>
        <span></span>
        <span></span>Scroll
      </a>
    </header>
  );
  // );
}

export default Header;
