import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../../utils";
function Section1() {
  const [topText, setTopText] = useState("");
  const [sectionData, setSectionData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  energyAdvisoryPages(where: { id: 261 }) {
    nodes {
      content
     featuredImage {
      node {
        sourceUrl
      }
    }
      allPageData {
        mainHeading
      }
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(responseData.data.energyAdvisoryPages.nodes[0]);
        setSectionData(responseData.data.energyAdvisoryPages.nodes[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchTop = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  energyAdvisoryPages(where: { id: 260 }) {
    nodes {
      content
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(responseData.data.energyAdvisoryPages.nodes[0].content);
        setTopText(responseData.data.energyAdvisoryPages.nodes[0].content);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchTop();
  }, []);

  return (
    <div className="advisory-main my-4" id="advisory">
      <div
        className="container py-4 d-flex flex-column align-items-center"
        data-aos="zoom-in-up"
        data-aos-duration="1000"
      >
        <div className="row justify-content-center align-items-center d-flex text-center">
          <div
            className="col-sm-12 col-md-9 col-lg-6"
            dangerouslySetInnerHTML={{ __html: topText }}
          ></div>
        </div>
        <div className="vl"></div>
      </div>
      <div data-aos="fade-up" data-aos-duration="1000">
        <div className="row eng-lower-div justify-content-start align-items-center py-5 px-3 px-sm-4 px-md-5">
          {sectionData && sectionData.allPageData && (
            <>
              <div
                className="col-12 col-lg-7 p-0 my-4"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <div className="text-light">
                  <h2 className="text-start sec2-content text-light">
                    {sectionData.allPageData.mainHeading}
                  </h2>
                  {sectionData.content && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sectionData.content,
                      }}
                    ></div>
                  )}
                </div>
              </div>
              {sectionData.featuredImage && sectionData.featuredImage.node && (
                <div
                  className="col-12 col-lg-4"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  <img
                    src={sectionData.featuredImage.node.sourceUrl}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Section1;
