import React, { useState } from "react";
import { Card } from "react-bootstrap";

const FeatureCard = ({ imgUrl, title, desc }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      className="col-sm-6 p-0 ps-md-3 my-2 my-md-0 pe-sm-1"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Card className="card m-card shadow-lg rounded-3">
        <Card.Body style={{ height: "255px" }}>
          <Card.Img
            variant="top"
            src={imgUrl}
            className={`${isHovered ? "m-0" : "mt-5"}`}
          />
        
          <Card.Title>
            <h4 className="card-title">{title}</h4>
          </Card.Title>
          <Card.Text dangerouslySetInnerHTML={{ __html: desc }}></Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default FeatureCard;
