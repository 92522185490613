import React, { useState, useEffect } from "react";
import FeatureCard from "../Cards/FeatureCard";
import { BASE_URL } from "../../../utils";

function Section2() {
  const [topData, setTopData] = useState([]);
  const [bottomData, setBottomData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  meteringPageSections(where: {termTaxonomyId: "40"}) {
    nodes {
      topdata: meteringSolutionsPages(where: {id: 537, orderby: {field: DATE, order: ASC}}) {
        nodes {
         title
         content
        featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
       bottomdata: meteringSolutionsPages( where: {orderby: {field: DATE, order: ASC}, notIn: "537"} ) {
       nodes {
         title
         content
        featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
      }
      }
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(
        //   responseData.data.meteringPageSections.nodes[0].bottomdata.nodes
        // );
        setTopData(
          responseData.data.meteringPageSections.nodes[0].topdata.nodes[0]
        );
        setBottomData(
          responseData.data.meteringPageSections.nodes[0].bottomdata.nodes
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div
      className="container-fluid section-bg"
      data-aos="zoom-in-up"
      data-aos-duration="1000"
    >
      <div className="container section-bg-in p-0 my-5">
        <div className="row m-0">
          <div className="col-sm-12 p-0">
            {topData && (
              <>
                <h2 className="text-light">{topData.title}</h2>
                <div
                  dangerouslySetInnerHTML={{ __html: topData.content }}
                ></div>
              </>
            )}
            <div className="BoxWrapper d-flex flex-wrap justify-content-center align-items-start">
              {bottomData &&
                bottomData.map((item, index) => (
                  <FeatureCard
                    imgUrl={item.featuredImage.node.sourceUrl}
                    title={item.title}
                    desc={item.content}
                    key={index}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section2;
