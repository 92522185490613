import React, { useState, useEffect } from "react";
import { BASE_URL, removeTag } from "../../../utils";
import { images } from "../../../res/images";

function Section1() {
  const [topData, setTopData] = useState([]);
  const [bottomData, setBottomData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
iTDivSections(where: {termTaxonomyId: "21"}) {
    nodes {
      topdata: iTDivisionPages(where: {id: 327, orderby: {field: DATE, order: ASC}}) {
        nodes {
        content
          title
         featuredImage {
            node {
              sourceUrl
            }
          }
          allPageData {
            mainHeading
            subHeading
          }

        }
      }
      bottomdata: iTDivisionPages(
        where: {orderby: {field: DATE, order: ASC}, notIn: "327"}
      ) {
        nodes {
         content
          allPageData {
            mainHeading
          }
          
        }
      }
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(responseData.data.iTDivSections.nodes[0].bottomdata.nodes);
        setTopData(responseData.data.iTDivSections.nodes[0].topdata.nodes[0]);
        setBottomData(
          responseData.data.iTDivSections.nodes[0].bottomdata.nodes
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div>
      <div className="container">
        <div className="row my-5">
          <div className="col-lg-6">
            <div className="position-relative left-section-pow">
              <img
                src={images.manpoweConsulting}
                alt=""
                className="img-fluid rounded-4 shadow"
              />

              <div></div>
            </div>
          </div>
          <div className="col-lg-6 powerful ">
            {topData && topData.title && (
              <h3 className="main-heading fs-5 mb-1 text-capitalize mt-0">
                {topData.title}
              </h3>
            )}
            {topData && topData.allPageData && (
              <h2 className=" sec2-content text-start fs-4">
                {topData.allPageData.mainHeading}
              </h2>
            )}
            {topData && topData.content && (
              <div dangerouslySetInnerHTML={{ __html: topData.content }}></div>
            )}
            {topData && topData.allPageData && (
              <h4 className="fw-600 tech-h4">
                {topData.allPageData.subHeading}
              </h4>
            )}
            <ul className="tech-ul my-1">
              {bottomData &&
                bottomData.length > 0 &&
                bottomData.map((item, id) => (
                  <li key={id}>
                    <span>
                      <i className="fa-solid fa-circle"></i>
                    </span>
                    <span>
                      <strong>{item.allPageData.mainHeading}</strong>
                      {"    "}
                      {removeTag(item.content)}
                    </span>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="container my-2">
        <div className="hr">
          <div className="line1"></div>
          <div className="line2"></div>
        </div>
      </div>
    </div>
  );
}

export default Section1;
