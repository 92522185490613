import React, { useState, useEffect } from "react";
import Slide from "./Slide";
import { BASE_URL } from "../../../utils";
function Projects() {
  const [topData, setTopData] = useState("");
  const [slideData, setSlideData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  solarPageSections(where: {termTaxonomyId: "38"}) {
    nodes {
      topdata: solarPages(where: {id: 527, orderby: {field: DATE, order: ASC}}) {
        nodes {
         title
        }
      }
      bottomdata: solarPages( where: {orderby: {field: DATE, order: ASC}, notIn: "527"} ) {
        nodes {
          featuredImage {
            node {
              sourceUrl
            }
          }
         
        }
      }
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(
        //   responseData.data.solarPageSections.nodes[0].bottomdata.nodes[0]
        //     .featuredImage.node.sourceUrl
        // );
        setTopData(
          responseData.data.solarPageSections.nodes[0].topdata.nodes[0].title
        );
        setSlideData(
          responseData.data.solarPageSections.nodes[0].bottomdata.nodes
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="better">
      <div className="container-fluid">
        <div className="row d-flex flex-column">
          <div className="col">
            {topData && (
              <p className="text-center fs-1 fw-bolder text-uppercase">
                {topData}
              </p>
            )}
          </div>
          <div className="col">
            <section id="trending">
              <div className="swiper trending-slider">
                <Slide slideData={slideData} />
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
